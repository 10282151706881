import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const GroupInfoStyled = styled(Grid.Column)<{ $zIndex: number }>`
  position: absolute !important;

  right: 0px;
  max-width: 260px !important;

  ${({ $zIndex }) =>
    $zIndex &&
    `
    z-index: ${$zIndex} !important;
  `}
`;

export const Styled = {
  GroupInfoStyled,
};
