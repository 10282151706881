import type { MessageBubbleEditorProps } from './MessageBubbleEditor.types';
import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import { normalizeChatText } from 'Components/Chat/utils';
import type ContentEditable from 'Components/ContentEditable';
import { EmojiButton } from 'Components/shared/EmojiButton';
import { TextButton } from 'Components/shared/TextButton';
import type { MENTION_PREFIX } from 'Constants/enums';
import { MobXProviderContext } from 'mobx-react';
import { useUIStore } from 'Modules/ui/index.store';
import * as React from 'react';

import type { RootStoreProps } from 'Stores/RootStore.types';
import { pushToGTMDataLayer } from 'Utils/analytics';
import {
  findMentionInsertData,
  focusContentEditable,
  getSelectionRange,
  insertEmoji,
  placeCursorAtEndOfElement,
} from 'Utils/inputNodeUtils';
import { keyIsAlphanumeric, keyIsValidFilterInput } from 'Utils/keyboardInput';
import {
  handleAlphanumericWhenMentionListOpen,
  handleAtSymbol,
  handleBackspaceDeleteWhenMentionListOpen,
  handleEnterKey,
  handleTabRightArrowSpacebarWhenMentionListOpen,
  handleUpDownLeftArrowsWhenMentionListOpen,
} from 'Utils/mentionKeyHandlers';
import { processMessage } from 'Utils/processMessage';

import { turndownSvc } from 'Utils/turndownSvc';
import { Styled } from './MessageBubbleEditor.styles';
import { ReplyBox } from './ReplyBox';

const testid = 'messageBubbleEditor';

export const MessageBubbleEditor: React.FC<MessageBubbleEditorProps> = ({
  conversationId,
  message,
  compileEditorMarkdown,
}) => {
  const {
    person: { loggedInPersonId },
    ui: {
      setConversationAndTotalUnreadCount,
      selectPersonPresenceStatus,
      selectConversationUnreadCounts,
      setMessageIdToScroll,
    },
    participant: {
      selectParticipantPersons,
      updateMyLastReadMessage,
      selectFilteredOtherParticipantPersonsInCurrentConversation,
    },
    message: {
      editMessage,
      setEditMessageMentionSelectedParticipantId,
      editMessageMentionSelectedParticipantId,
      editMessageMentionFilter,
      setEditMessageMentionFilter,
      editMessageMentionListOpen,
      setEditMessageMentionListOpen,
    },
    conversation: { CurrentConversation },
  } = React.useContext<RootStoreProps>(MobXProviderContext);
  const { editMessageId, setEditMessageId } =
    React.useContext(ChatLayoutContext);

  const contentEditorRef = React.useRef<ContentEditable>(null);

  const chatText = message.chat?.text_v2 || message.chat?.text;

  const [editMessageDraftRaw, setEditMessageDraftRaw] = React.useState(
    chatText || ''
  );
  const [editMessageDraftHtml, setEditMessageDraftHtml] = React.useState(
    compileEditorMarkdown(
      normalizeChatText(message.chat?.text_v2) || message.chat?.text || ''
    )
  );
  const { emojiPickerState, setEmojiPickerState } = useUIStore();

  const [replyBoxRef, setReplyBoxRef] = React.useState(
    message.chat?.text_v2?.match(/^@ref_{[\w\d-]*} /)
  );

  const isGroupChat =
    CurrentConversation?.grouping === 'Channel' ||
    CurrentConversation?.grouping === 'Group';

  React.useEffect(() => {
    if (
      editMessageId &&
      window.getSelection().toString().length === 0 &&
      !emojiPickerState.open &&
      contentEditorRef.current?.htmlEl
    ) {
      focusContentEditable(contentEditorRef.current, true);
    }
    // don't remove contentEditorRef?.current dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentEditorRef?.current, emojiPickerState, editMessageId]);

  React.useEffect(() => {
    if (editMessageMentionListOpen) {
      setEditMessageMentionListOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isDeadkeyPressed = false;
  let previousKeyWasDeadKey = false;

  const insertMentionAtCursor = (prefix: MENTION_PREFIX, value: string) => {
    const { start, end, htmlStart, htmlEnd } =
      contentEditorRef.current.selectionRange;

    const rawInsertData = findMentionInsertData(editMessageDraftRaw, start);
    const emdRaw =
      editMessageDraftRaw.substring(0, rawInsertData.insertIndex) +
      `${rawInsertData.prependWith}${prefix}${value} ` +
      editMessageDraftRaw.substring(end);
    setEditMessageDraftRaw(emdRaw);

    if (prefix === '@pr') {
      const personId = parseInt(value, 10);
      const mentioned = selectParticipantPersons(conversationId).find(
        (p) => p.person.id === personId
      ); // Mentioned Participant/Person
      if (mentioned !== undefined) {
        const htmlInsertData = findMentionInsertData(
          editMessageDraftHtml,
          htmlStart
        );
        const draftHtmlWithMentions =
          editMessageDraftHtml.substring(0, htmlInsertData.insertIndex) +
          `${htmlInsertData.prependWith}&nbsp;<div class="input-mention" mention-target="${prefix}${value}" contenteditable="false">@${mentioned.person.DisplayName}</div> &nbsp;` +
          editMessageDraftHtml.substring(htmlEnd);
        setEditMessageDraftHtml(draftHtmlWithMentions);
        setEditMessageMentionListOpen(false);
      }
    } else {
      const htmlInsertData = findMentionInsertData(
        editMessageDraftHtml,
        htmlStart
      );
      const draftHtmlWithMentions =
        editMessageDraftHtml.substring(0, htmlInsertData.insertIndex) +
        `${htmlInsertData.prependWith}&nbsp;<div class="input-mention" mention-target="${prefix}${value}" contenteditable="false">${prefix}${value}</div> &nbsp;` +
        editMessageDraftHtml.substring(htmlEnd);
      setEditMessageDraftHtml(draftHtmlWithMentions);
      setEditMessageMentionListOpen(false);
    }

    // Move cursor to the end after inserting the mention
    setTimeout(() => {
      if (contentEditorRef.current?.htmlEl) {
        placeCursorAtEndOfElement(contentEditorRef.current.htmlEl);
      }
    }, 0);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const htmlValue = e.target.value?.replace('\n', '<br>');
    const rawResultValue = turndownSvc.turndown(htmlValue);

    if (!isDeadkeyPressed) {
      setEditMessageDraftRaw(rawResultValue);
      setEditMessageDraftHtml(rawResultValue ? htmlValue : '');
    }
  };

  const submitEditMessage = async () => {
    if (editMessageDraftRaw === chatText) {
      setEditMessageMentionListOpen(false);
      return setEditMessageId('');
    }
    if (editMessageDraftHtml.includes('class="input-mention"')) {
      pushToGTMDataLayer('mention', { conversationId });
    }

    await editMessage(conversationId, message.id, {
      text: editMessageDraftRaw,
      text_v2: replyBoxRef
        ? `${replyBoxRef} ${editMessageDraftRaw}`
        : editMessageDraftRaw,
    });

    setEditMessageMentionListOpen(false);
    setEditMessageId('');

    setEditMessageDraftRaw('');
    setEditMessageDraftHtml('');
  };

  const editOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (previousKeyWasDeadKey && !isDeadkeyPressed) {
      previousKeyWasDeadKey = false;
      return;
    }

    if (e.key === 'Dead') {
      isDeadkeyPressed = true;
      previousKeyWasDeadKey = true;
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      if (contentEditorRef.current?.htmlEl === document.activeElement) {
        emojiPickerState.open
          ? setEmojiPickerState({ open: false, editing: false })
          : setEditMessageId('');
      }
    }

    // Enter
    if (e.key === 'Enter') {
      void handleEnterKey(
        e,
        true, // TODO: Pass prop down from PusherStore (RP 2018-11-21)
        conversationId,
        editMessageMentionListOpen,
        editMessageMentionSelectedParticipantId,
        insertMentionAtCursor,
        selectParticipantPersons,
        submitEditMessage
      );
    }

    // @ symbol (shift + 2), pop up the mentions list
    if (isGroupChat && e.key === '@') {
      handleAtSymbol(
        e,
        contentEditorRef.current.selectionRange,
        editMessageDraftHtml,
        editMessageMentionListOpen,
        setEditMessageMentionListOpen,
        setEditMessageMentionSelectedParticipantId,
        setEmojiPickerState
      );
    }

    // Handle Up/Down arrows, Backspace, and Delete when the Mention list is open
    if (isGroupChat && editMessageMentionListOpen) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft'].includes(e.key)) {
        handleUpDownLeftArrowsWhenMentionListOpen(
          e,
          selectFilteredOtherParticipantPersonsInCurrentConversation,
          editMessageMentionSelectedParticipantId,
          setEditMessageMentionSelectedParticipantId,
          setEditMessageMentionListOpen,
          editMessageMentionFilter
        );
      } else if (['ArrowRight', 'Tab', ' '].includes(e.key)) {
        handleTabRightArrowSpacebarWhenMentionListOpen(
          e,
          editMessageMentionSelectedParticipantId,
          selectParticipantPersons,
          conversationId,
          insertMentionAtCursor
        );
      } else if (['Backspace', 'Delete'].includes(e.key)) {
        handleBackspaceDeleteWhenMentionListOpen(
          e,
          contentEditorRef.current.selectionRange,
          editMessageDraftHtml,
          editMessageMentionListOpen,
          setEditMessageMentionListOpen,
          editMessageMentionFilter,
          setEditMessageMentionFilter,
          selectFilteredOtherParticipantPersonsInCurrentConversation,
          setEditMessageMentionSelectedParticipantId
        );
      } else if (keyIsAlphanumeric(e)) {
        handleAlphanumericWhenMentionListOpen(
          e,
          editMessageMentionListOpen,
          editMessageMentionFilter,
          setEditMessageMentionFilter,
          selectFilteredOtherParticipantPersonsInCurrentConversation,
          setEditMessageMentionSelectedParticipantId
        );
      }
      // Any invalid inputs should close the Mention list to avoid auto-complete desync
      else if (!keyIsValidFilterInput(e)) {
        setEditMessageMentionListOpen(false);
      }
    }
  };

  const scrollViewportToCurrentMessage = () => {
    setMessageIdToScroll(message.id);
  };

  const toggleShowEmojis = (open: boolean) => {
    if (open) {
      scrollViewportToCurrentMessage();
      setEmojiPickerState({ open: true, editing: true });
      setEditMessageMentionListOpen(false);
      if (contentEditorRef.current?.htmlEl) {
        const contentEditorRefSelection = getSelectionRange(
          contentEditorRef.current.htmlEl
        );
        // Focus and move to end if there is no selection (unfocused) in the input
        if (
          contentEditorRefSelection.start === 0 &&
          contentEditorRefSelection.end === 0
        ) {
          focusContentEditable(contentEditorRef.current);
          placeCursorAtEndOfElement(contentEditorRef.current.htmlEl);
        }
      }
    } else {
      setEmojiPickerState({ open: false, editing: false });
    }
  };

  return (
    <Styled.EditWrapper className="edit-wrapper">
      <Styled.InputArea>
        {replyBoxRef && message.references?.[0] && (
          <ReplyBox
            {...{ reference: message.references[0] }}
            onRemoveClick={() => {
              setEditMessageDraftRaw(normalizeChatText(editMessageDraftRaw));
              setReplyBoxRef(null);
            }}
          />
        )}
        <Styled.ContentEditable
          ref={contentEditorRef}
          className="edit-box"
          placeholder="Type your message"
          html={editMessageDraftHtml}
          onKeyDown={editOnKeyDown}
          onKeyUp={(e) => e.key === 'Dead' && (isDeadkeyPressed = false)}
          updateMessage={(inputValue) =>
            processMessage({
              inputValue,
              onHandleDraftRaw: setEditMessageDraftRaw,
              onHandleDraftHtml: setEditMessageDraftHtml,
              isDeadKeyPressed: isDeadkeyPressed,
            })
          }
          {...{ onChange }}
        />
        <Styled.HoverButtons>
          {isGroupChat && (
            <Styled.AtMentionMembers
              {...{
                conversationId,
                getEmojiPickerState: () => emojiPickerState,
                insertMentionAtCursor,
                contentEditableRef: contentEditorRef,
                loggedInPersonId,
                mentionListOpen: editMessageMentionListOpen,
                messageMentionFilter: editMessageMentionFilter,
                selectedMentionParticipantId:
                  editMessageMentionSelectedParticipantId,
                selectFilteredOtherParticipantPersonsInCurrentConversation,
                selectParticipantPersons,
                selectPersonPresenceStatus,
                selectUnreadCounts: selectConversationUnreadCounts,
                setMentionListOpen: setEditMessageMentionListOpen,
                setMessageMentionFilter: setEditMessageMentionFilter,
                setSelectedMentionParticipantId:
                  setEditMessageMentionSelectedParticipantId,
                setEmojiPickerState,
                setConversationAndTotalUnreadCount,
                updateMyLastReadMessage,
              }}
              onKeyDown={editOnKeyDown}
              onOpen={scrollViewportToCurrentMessage}
              resolveConversationLinkPath={null}
            />
          )}
          <EmojiButton
            isOpen={emojiPickerState.open && emojiPickerState.editing}
            onOpen={() => toggleShowEmojis(true)}
            onClose={() => toggleShowEmojis(false)}
            onChooseEmoji={(emoji) =>
              insertEmoji(emoji, contentEditorRef.current)
            }
            relativePosition
          />
        </Styled.HoverButtons>
      </Styled.InputArea>
      <Styled.EditActions>
        <Styled.CancelButton
          onClick={() => {
            setEditMessageMentionListOpen(false);
            setEditMessageId('');
          }}
          testid={`${testid}-buttonClose`}
          variant="secondary"
        >
          Cancel
        </Styled.CancelButton>
        <TextButton
          onClick={submitEditMessage}
          disabled={{
            condition: !editMessageDraftRaw,
            title: 'Type a message before saving',
          }}
          variant="primary"
          testid={`${testid}-buttonSave`}
        >
          Save
        </TextButton>
      </Styled.EditActions>
    </Styled.EditWrapper>
  );
};
