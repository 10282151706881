import { KEYCODES } from 'Constants/env';
import * as levenshtein from 'fast-levenshtein';
import { IParticipantPerson } from 'Interfaces/participantPerson';
import { find, findIndex, get } from 'lodash';
import { ITransformer } from 'mobx-utils';
import { ConversationModel, MessageModel } from 'Models/index';
import { IEmojiPickerState } from 'Stores/UiStore';
import { ISelectionRange } from 'Utils/inputNodeUtils';
import { toggleEdit } from './editMessage';

export async function handleEnterKey(
  e: React.KeyboardEvent<HTMLInputElement>,
  isOnline: boolean,
  conversationId: string,
  mentionListOpen: boolean,
  selectedMentionParticipantId: string,
  insertMentionAtCursor: (prefix: string, value: string) => void,
  selectOtherParticipantPersons: ITransformer<string, IParticipantPerson[]>,
  sendMessage:
    | ((e: React.KeyboardEvent<HTMLInputElement>) => Promise<void>)
    | ((e: React.KeyboardEvent<HTMLInputElement>) => void)
) {
  // Shift NOT held
  if (!e.shiftKey) {
    // Default behavior if the Mention list isn't open
    if (!mentionListOpen) {
      e.preventDefault();
      // If online, send the message and reset the input
      if (isOnline) {
        await sendMessage(e);
      }
    } else {
      insertMention(
        e,
        selectedMentionParticipantId,
        selectOtherParticipantPersons,
        conversationId,
        insertMentionAtCursor
      );
    }
  }
}

export function handleEscapeKey(
  e: React.KeyboardEvent<HTMLElement>,
  setEmojiPickerState: (state: IEmojiPickerState) => void
) {
  setEmojiPickerState({ open: false, editing: false });
}

function insertMention(
  e: React.KeyboardEvent<HTMLInputElement>,
  selectedMentionParticipantId: string,
  selectOtherParticipantPersons: ITransformer<string, IParticipantPerson[]>,
  conversationId: string,
  insertMentionAtCursor: (prefix: string, value: string) => void
) {
  e.preventDefault();
  const spId = selectedMentionParticipantId;
  if (
    spId !== null &&
    spId !== 'here' &&
    selectOtherParticipantPersons(conversationId) !== null
  ) {
    const selPP = find(
      selectOtherParticipantPersons(conversationId),
      (p) =>
        p.participant.id === spId ||
        spId.includes(p.participant.personId.toString())
    );
    if (selPP.person && selPP.person.id) {
      insertMentionAtCursor('@pr', selPP.person.id.toString());
    } else {
      console.warn(`Failed to mention Participant with Id ${selPP}`);
    }
  } else if (spId === 'here') {
    insertMentionAtCursor('@', 'here');
  }
}

export function handleUpArrowEditOnEmptyInput(
  e: React.KeyboardEvent<HTMLInputElement>,
  curConversation: ConversationModel,
  selectParticipantPersons: ITransformer<string, IParticipantPerson[]>,
  newestMessageOwnedByUserId: MessageModel,
  setEditMessageDraftRaw: (rawContent: string) => void,
  setEditMessageDraftHtml: (htmlContent: string) => void,
  isEditingMessageId: string,
  setIsEditingMessageId: (id: string) => void
) {
  // When pressing up, we want to edit the newest message owned by logged in user
  // Prevent Default so that it doesn't go to second line in a multi-line textarea input
  e.preventDefault();
  toggleEdit(
    curConversation,
    selectParticipantPersons,
    get(newestMessageOwnedByUserId, 'chat.text', ''),
    newestMessageOwnedByUserId.id,
    setEditMessageDraftRaw,
    setEditMessageDraftHtml,
    isEditingMessageId,
    setIsEditingMessageId
  );
}

export function handleAtSymbol(
  e: React.KeyboardEvent<HTMLInputElement>,
  selectionRange: ISelectionRange,
  messageDraftHtml: string,
  mentionListOpen: boolean,
  setMentionListOpen: (open: boolean) => void,
  setSelectedMentionParticipantId: (participantId: string) => void,
  setEmojiPickerState: (emojiPickerState: Partial<IEmojiPickerState>) => void
) {
  const { isFocusInsideMention } = selectionRange;
  let { htmlEnd } = selectionRange;
  if (htmlEnd > messageDraftHtml.length) htmlEnd = messageDraftHtml.length;

  const htmlEndNbsp = messageDraftHtml.lastIndexOf('&nbsp;');
  if (htmlEndNbsp > htmlEnd) htmlEnd = htmlEndNbsp;

  // Adjusted condition: Remove the whitespace requirement
  const shouldOpenMentionList = !isFocusInsideMention && !mentionListOpen;

  if (shouldOpenMentionList) {
    setMentionListOpen(true);
    setSelectedMentionParticipantId('here');
    setEmojiPickerState({ open: false, editing: false });
  }
}

export function handleTabRightArrowSpacebarWhenMentionListOpen(
  e: React.KeyboardEvent<HTMLInputElement>,
  selectedMentionParticipantId: string,
  selectOtherParticipantPersons: ITransformer<string, IParticipantPerson[]>,
  conversationId: string,
  insertMentionAtCursor: (prefix: string, value: string) => void
) {
  insertMention(
    e,
    selectedMentionParticipantId,
    selectOtherParticipantPersons,
    conversationId,
    insertMentionAtCursor
  );
}

export function handleUpDownLeftArrowsWhenMentionListOpen(
  e: React.KeyboardEvent<HTMLInputElement>,
  selectFilteredOtherParticipantPersons: ITransformer<
    string,
    IParticipantPerson[]
  >,
  selectedMentionParticipantId: string,
  setSelectedMentionParticipantId: (participantId: string) => void,
  setMentionListOpen: (open: boolean) => void,
  mentionFilter: string
) {
  // Always close on left/right arrow, to avoid desync of auto-complete, since it is keypress based
  if (e.key === 'ArrowLeft') {
    setMentionListOpen(false);
  } else {
    const otherPtcPersons =
      selectFilteredOtherParticipantPersons(mentionFilter);
    if (otherPtcPersons !== null) {
      // As long as there is at least 1 other Participant, we can key between @here and the Participant(s)
      if (otherPtcPersons.length > 0) {
        const ptcId = selectedMentionParticipantId;
        const spIndex = findIndex(
          otherPtcPersons,
          (p) =>
            p.participant.id === ptcId ||
            p.participant.personId.toString() === ptcId
        );
        if (e.keyCode === KEYCODES.UPARROW) {
          // @here is highlighted, roll over to the end of the list
          if (ptcId === 'here') {
            setSelectedMentionParticipantId(
              otherPtcPersons[otherPtcPersons.length - 1].participant.id
            );
          } else if (spIndex === 0) {
            setSelectedMentionParticipantId('here'); // @here
          } else if (spIndex > 0) {
            setSelectedMentionParticipantId(
              otherPtcPersons[spIndex - 1].participant.id
            );
          }
        } else if (e.keyCode === KEYCODES.DOWNARROW) {
          // @here is highlighted, increment index to move down to the first Participant in the list
          if (spIndex === otherPtcPersons.length - 1) {
            setSelectedMentionParticipantId('here'); // @here
          } else {
            setSelectedMentionParticipantId(
              otherPtcPersons[spIndex + 1].participant.id ||
                otherPtcPersons[spIndex + 1].participant.personId.toString()
            );
          }
        }
      }
    }
  }
}

export function handleBackspaceDeleteWhenMentionListOpen(
  e: React.KeyboardEvent<HTMLInputElement>,
  selectionRange: ISelectionRange,
  messageDraftHtml: string,
  mentionListOpen: boolean,
  setMentionListOpen: (open: boolean) => void,
  mentionFilter: string,
  setMentionFilter: (filterVal: string) => void,
  selectFilteredOtherParticipantPersons: ITransformer<
    string,
    IParticipantPerson[]
  >,
  setSelectedMentionParticipantId: (participantId: string) => void
) {
  const { htmlEnd } = selectionRange;

  const isAfterAt = messageDraftHtml[htmlEnd - 1] === '@';

  if (mentionListOpen) {
    // Backspace while after @, Delete, or Spacebar (` `)
    if (
      (isAfterAt && e.key === 'Backspace') ||
      e.key === 'Delete' // Always close on Delete key
    ) {
      setMentionListOpen(false);
    } else if (e.key === 'Backspace') {
      const newMentionFilter: string =
        mentionFilter.length > 1
          ? mentionFilter.substring(0, mentionFilter.length - 1)
          : '';
      setMentionFilter(newMentionFilter);

      const filteredPtcPersons =
        selectFilteredOtherParticipantPersons(newMentionFilter);
      if (filteredPtcPersons !== null) {
        if (filteredPtcPersons.length > 0) {
          let closestPtcPrs: IParticipantPerson = null;
          if (newMentionFilter.length > 0) {
            closestPtcPrs = getClosestFilteredParticipantPerson(
              newMentionFilter,
              filteredPtcPersons
            );
            if (closestPtcPrs === null) {
              closestPtcPrs = filteredPtcPersons[0]; // Shouldn't happen, but fall back to the first item if necessary
            }
          } else {
            closestPtcPrs = filteredPtcPersons[0]; // Empty filter, just highlight the first ParticipantPerson
          }

          setSelectedMentionParticipantId(closestPtcPrs.participant.id);
        } else {
          setSelectedMentionParticipantId('here');
        }
      }
    }
  }
}

/**
 * When the Mention list is open, handles alphanumeric (`a-zA-Z0-9`) inputs to filter the list
 * of `IParticipantPerson`s (AKA mention auto-complete).
 *
 * Finds the `IParticipantPerson` with a `firstName` _or_ `lastName` with the lowest Levenshtein
 * distance from `mentionFilter`
 *
 * @export
 * @param e
 * @param mentionListOpen Whether the Mention list is currently open
 * @param mentionFilter The filter value used to compute distance from `firstName` or `lastName`
 * @param setMentionFilter Setter for the filter value used to compute distance from `firstName` or `lastName`
 * @param selectFilteredOtherParticipantPersons Provide `ParticipantStore#selectFilteredOtherParticipantPersonsInCurrentConversation` (or a compatible method)
 * @param setSelectedMentionParticipantId Setter for the `ParticipantPerson` who will be selected/highlighted in the Mention list
 */
export function handleAlphanumericWhenMentionListOpen(
  e: React.KeyboardEvent<HTMLInputElement>,
  mentionListOpen: boolean,
  mentionFilter: string,
  setMentionFilter: (filterVal: string) => void,
  selectFilteredOtherParticipantPersons: ITransformer<
    string,
    IParticipantPerson[]
  >,
  setSelectedMentionParticipantId: (participantId: string) => void
) {
  if (mentionListOpen) {
    const newMentionFilter: string = mentionFilter + e.key;
    setMentionFilter(newMentionFilter);
    const filteredPtcPersons =
      selectFilteredOtherParticipantPersons(newMentionFilter);
    if (filteredPtcPersons !== null) {
      if (filteredPtcPersons.length > 0) {
        let closestPtcPrs = getClosestFilteredParticipantPerson(
          newMentionFilter,
          filteredPtcPersons
        );
        if (closestPtcPrs === null) {
          closestPtcPrs = filteredPtcPersons[0]; // Shouldn't happen, but fall back to the first item if necessary
        }
        setSelectedMentionParticipantId(
          closestPtcPrs.participant.id ||
            closestPtcPrs.participant.personId.toString()
        );
      } else {
        setSelectedMentionParticipantId('here');
      }
    }
  }
}

/**
 * Find the `IParticipantPerson` in the array who has a `firstName` _or_ `lastName` with
 * the lowest Levenshtein distance from `mentionFilter`, in order to determine who to select.
 *
 * @param mentionFilter
 * @param participantPersons
 * @returns
 */
function getClosestFilteredParticipantPerson(
  mentionFilter: string,
  participantPersons: IParticipantPerson[]
) {
  let lastClosestPtcPrs: IParticipantPerson = null;
  let lastClosestDistance = -1;
  participantPersons.forEach((pp) => {
    const clsNameDist = Math.min(
      levenshtein.get(mentionFilter, pp.person.firstName),
      levenshtein.get(mentionFilter, pp.person.lastName)
    );
    if (
      lastClosestPtcPrs === null ||
      lastClosestDistance === -1 ||
      clsNameDist < lastClosestDistance
    ) {
      lastClosestPtcPrs = lastClosestPtcPrs;
      lastClosestDistance = clsNameDist;
    }
  });
  return lastClosestPtcPrs;
}
