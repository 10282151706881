import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const VideoHistoryChat = styled(Grid.Column)<{
  $zIndex: number;
}>`
  border-left: 0.5px solid rgba(51, 62, 72, 0.2);
  position: absolute !important;
  height: calc(100% - 70px);

  ${({ $zIndex }) =>
    $zIndex &&
    `
    z-index: ${$zIndex} !important
  `};
`;

export const Styled = {
  VideoHistoryChat,
};
