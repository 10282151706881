import { useMemo } from "react";
import { isSourceStale } from "Utils/contactSyncSource";

const useWarningMessage = (
  showWarning: boolean,
  sourceType: string,
  isV2Source: boolean,
  sourceState: string,
  edit = false
) => {
  const warningMessage = useMemo(() => {
    if (!showWarning) {
      return '';
    }

    if (sourceType.toLowerCase() === 'icloud') {
      return edit
        ? 'iCloud isn’t a supported source. Any changes will be saved, but won’t be synced with iCloud.'
        : 'iCloud isn’t supported anymore. Your contacts won’t be synced.';
    }
    if (isV2Source) {
      return 'we couldn’t synchronize with this account, please remove and add it again.';
    }

    if (isSourceStale(sourceState)) {
      return 'this account isn’t synced anymore, please login again.';
    }

    return '';
  }, [showWarning, sourceType, isV2Source, sourceState, edit]);

  return warningMessage;
};

export default useWarningMessage;