import bhiveIcon from 'Assets/images/bhive-logo-short.svg';
import smsIcon from 'Assets/images/directory-chat.svg';
import makeCallIcon from 'Assets/images/phone-directory.svg';
import { debounce, uniqBy } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';

import { Contact } from 'Models/Contacts';
import * as React from 'react';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Grid,
  Input,
  Loader,
  Popup,
} from 'semantic-ui-react';
import { RootStoreProps } from '../../stores/RootStore.types';
import PhoneNumbersPopup from '../PhoneNumbersPopup';
import { Heading } from '../shared/Heading';
import { UserAvatar } from '../shared/UserAvatar';
import { ContactsLinks } from './ContactsLinks';

const permanentProviders: DropdownItemProps[] = [
  { key: 'displayAll', text: 'Display all', value: '' },
  {
    key: 'bHivePersonal',
    image: { avatar: true, src: bhiveIcon },
    text: 'Personal contacts',
    value: 'B_HIVE_PERSONAL',
  },
  {
    key: 'bHiveOrganization',
    image: { avatar: true, src: bhiveIcon },
    text: 'Organization contacts',
    value: 'B_HIVE_SHARED',
  },
];

export default observer(() => {
  const { person, phoneCall, conversation, ui } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const navigate = useNavigate();

  const [selectedContact, setSelectedContact] = useState<Contact>(null);
  const [operationType, setOperationType] = useState<'call' | 'message'>(null);
  const [dropdownOption, setDropdownOption] =
    useState<DropdownItemProps[]>(null);

  const mapOptionsToFilter = () => {
    const externalProviders: DropdownItemProps[] = person.allSources
      ?.map((item) => {
        const isForContacts = item.scopes.includes('contacts');
        return (
          isForContacts && {
            key: item.id,
            image: {
              avatar: true,
              src: item.providerImageSmall,
              style: item.provider === 'microsoft' ? { maxWidth: 20 } : {},
            },
            text: item.email,
            value: item.id,
          }
        );
      })
      .filter(Boolean);

    const mergedProvides = [...permanentProviders, ...externalProviders];
    if (mergedProvides.length > dropdownOption?.length || !dropdownOption) {
      setDropdownOption(mergedProvides);
    }
  };

  const filterList = debounce((q: string) => {
    const {
      contactPageNumber,
      contactSearchTerm,
      contactFilterValue,
      setContactPageNumber,
      getSearchListContacts,
    } = person;

    let pageNumber = contactPageNumber;
    if (
      (contactSearchTerm || contactFilterValue?.value) &&
      contactPageNumber > 1
    ) {
      setContactPageNumber(1);
      //dont remove pageNumber, because for what ever reason contactPageNumber is not updated, from source pick
      pageNumber = 1;
    }
    void getSearchListContacts(
      20,
      pageNumber,
      contactFilterValue?.value.toString() || '',
      contactSearchTerm
    );
  }, 500);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    e.preventDefault();
    person.setContactSearchTerm(value);
    filterList(value);
  };

  const makeCall =
    (contact: Contact) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (contact.phoneNumbers.length > 1) {
        setSelectedContact(contact);
        setOperationType('call');
      } else if (contact?.phoneNumbers.length === 1) {
        void phoneCall.callWithPerson(null, contact.phoneNumbers[0].number);
      }
    };

  const handleOpenChat =
    (contact: Contact) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (contact?.phoneNumbers.length > 1) {
        setSelectedContact(contact);
        setOperationType('message');
      } else if (contact?.phoneNumbers.length === 1) {
        const phone = contact?.phoneNumbers[0].number;
        const resp = await conversation.loadOrCreateConversationWithPost(
          null,
          phone
        );
        navigate(`/chat/conversations/${resp.data.id}/menu`);
      }
    };

  const handleLoadMoreContacts = () => {
    const {
      loadingInfinite,
      setContactPageNumber,
      getSearchListContacts,
      contactPageNumber,
      contactFilterValue,
      contactSearchTerm,
    } = person;

    if (!loadingInfinite) {
      const currentPage = contactPageNumber + 1;
      setContactPageNumber(currentPage);
      void getSearchListContacts(
        20,
        currentPage,
        contactFilterValue?.value?.toString() || '',
        contactSearchTerm,
        true
      );
    }
  };

  const handleSourcePick = (e, d: DropdownProps) => {
    const pickedSource = dropdownOption.find(
      (item) => item.provider === d.value || item.value === d.value
    );

    person.setContactPageNumber(1);

    if (pickedSource) {
      person.setContactFilterValue(pickedSource.value ? pickedSource : null);
      filterList(person.contactSearchTerm);
    }
  };

  const clearSelected = () => {
    setSelectedContact(null);
  };

  const openEditMode = (contact: Contact) => (e: Event) => {
    e.stopPropagation();
    person.setEditContact(contact);
    navigate('/addressBook/contact', { state: { from: '/contacts' } }); //
  };

  const getSourceProvider = (accountId: number) => {
    const provider = person.allSources.find(
      (source) => source.id === accountId
    )?.provider;
    return person.changeProviderName(provider || '');
  };

  const displayProvider = (contact: Contact) => {
    return contact.accountId ? getSourceProvider(contact.accountId) : 'B-Hive';
  };

  const mapOrgData = (contact: Contact) => {
    if (contact.organization && contact.organizationRole) {
      return `${contact.organization}, ${contact.organizationRole}`;
    } else if (contact.organization) {
      return contact.organization;
    } else if (contact.organizationRole) {
      return contact.organizationRole;
    }
    return '';
  };

  useEffect(() => {
    mapOptionsToFilter();
  });

  const filteredSourceOfData = person.allContacts.filter((contact) => contact);
  const currentDataLength = person.contactPageNumber * 20;

  return (
    <div className="all-contacts" id="contacts" onClick={clearSelected}>
      <Heading variant="h2">Contacts</Heading>
      <div className="flex-row top-15">
        <Input
          autoFocus={true}
          className="search-field"
          value={person.contactSearchTerm || ''}
          placeholder="Search"
          onChange={handleSearch}
        />
        <div className="filter-source">
          <div className="filter-text">Filter by source</div>
          <Dropdown
            data-private
            placeholder="Display all"
            fluid
            selection
            options={uniqBy(dropdownOption, 'key')}
            onChange={handleSourcePick}
            value={person.contactFilterValue?.value}
          />
        </div>
      </div>
      {person.loadingContacts ? (
        <Loader className="addressbook-loader" active indeterminate />
      ) : (
        <InfiniteScroll
          initialScrollY={1}
          dataLength={currentDataLength} //This is important field to render the next data
          next={handleLoadMoreContacts}
          hasMore={
            !person.loadingInfinite && person.totalContacts > currentDataLength
          }
          loader={<></>}
          endMessage={<></>}
          pullDownToRefreshThreshold={4}
          scrollableTarget="contactList"
        >
          <Grid columns="four" id="contactList" className="list">
            {filteredSourceOfData?.length > 0 ? (
              filteredSourceOfData.map((contact) => {
                const organizationData = mapOrgData(contact);
                return (
                  <Grid.Row
                    key={contact.id}
                    className="directory-items"
                    onClick={openEditMode(contact)}
                  >
                    <Grid.Column width="7">
                      <div className="flex-row">
                        <div className="flex-column">
                          <div className="flex-row flex-align-items-center">
                            {contact.pictureUrl ? (
                              <img
                                className="contact-prof-img"
                                src={contact.pictureUrl}
                              />
                            ) : (
                              <UserAvatar
                                name={
                                  contact.DisplayName().replace(/\s+/, '') ===
                                  ''
                                    ? null
                                    : contact.DisplayName()
                                }
                                selectUnreadCounts={
                                  ui.selectConversationUnreadCounts
                                }
                                conversationGrouping="OneOnOne"
                              />
                            )}
                            <div className="directory-user-info">
                              <div data-private>
                                {contact.DisplayName()}
                                {/* show this only if DisplayAll selected */}
                                {!person.contactFilterValue?.value && (
                                  <span className="provider">
                                    ({displayProvider(contact)})
                                  </span>
                                )}
                              </div>
                              <div>{organizationData}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column width="6">
                      <ContactsLinks {...{ contact, phoneCall }} />
                    </Grid.Column>
                    <Grid.Column width="3" textAlign="right">
                      <div>
                        {contact.phoneNumbers.length > 0 && (
                          <div className="flex-row flex-align-items-center flex-justify-end position-relative">
                            {selectedContact &&
                              selectedContact?.id === contact.id && (
                                <PhoneNumbersPopup
                                  phoneNumbers={contact.phoneNumbers}
                                  callWithPerson={phoneCall.callWithPerson}
                                  loadOrCreateConversationWithPost={
                                    conversation.loadOrCreateConversationWithPost
                                  }
                                  operationType={operationType}
                                />
                              )}
                            <Popup
                              key="start-chat"
                              inverted
                              position="bottom center"
                              content={'Start messaging'}
                              trigger={
                                <div>
                                  <Button
                                    onClick={handleOpenChat(contact)}
                                    size="small"
                                    compact
                                    basic
                                    className="flex-row flex-align-items-center"
                                  >
                                    <img
                                      className="icon-18px chat-icon"
                                      src={smsIcon}
                                    />
                                  </Button>
                                </div>
                              }
                            />

                            <Popup
                              key="start-call"
                              inverted
                              content={'Start Call'}
                              position="bottom center"
                              trigger={
                                <div className={'call-connecting'}>
                                  <Button
                                    onClick={makeCall(contact)}
                                    size="small"
                                    compact
                                    id="call-person"
                                    basic
                                    className="flex-row align-items-center"
                                  >
                                    {
                                      <img
                                        id="phone-call"
                                        className="icon-18px"
                                        src={makeCallIcon}
                                      />
                                    }
                                  </Button>
                                </div>
                              }
                            />
                          </div>
                        )}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                );
              })
            ) : (
              <div className="no-contacts">No contacts found</div>
            )}
          </Grid>
          {person.loadingInfinite && (
            <Loader className="loading-more-contacts" />
          )}
        </InfiniteScroll>
      )}
    </div>
  );
});
