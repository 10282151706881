import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const TopBarDialpadStyled = styled(Grid.Column)<{
  $zIndex: number;
  $isRightSidebarOpened: boolean;
  $isFixed: boolean;
}>`
  ${({ $isFixed }) =>
    $isFixed &&
    `
    position: absolute !important;
    
    height: calc(100% - 70px) !important;
  `}
  ${({ $zIndex }) =>
    $zIndex &&
    `
    z-index: ${$zIndex} !important
  `};
`;

export const Styled = {
  TopBarDialpadStyled,
};
