import type { ActivityListFilterProps, OptionRange } from './interfaces';
import { TIMEZONE_IDENTIFIER } from 'Constants/env';
import { IUnreadMessageAndMentionCountsByConversationId } from 'Interfaces/components';
import { isEmpty } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import * as React from 'react';
import { Dropdown, DropdownProps, List, Loader } from 'semantic-ui-react';
import { ConversationModel } from '../../models';
import ActivityListItem from '../ActivityListItem';

export enum OptionRangeKey {
  LAST_24_HOURS,
  LAST_7_DAYS,
  LAST_14_DAYS,
  LAST_30_DAYS,
  LAST_60_DAYS,
  LAST_90_DAYS,
  LAST_180_DAYS,
  LAST_YEAR,
  OLDER_THAN_LAST_YEAR,
}

const OPTION_TO_RANGE: Record<OptionRangeKey, OptionRange> = {
  [OptionRangeKey.LAST_24_HOURS]: { period: 1 },
  [OptionRangeKey.LAST_7_DAYS]: { period: 7 },
  [OptionRangeKey.LAST_14_DAYS]: { period: 14 },
  [OptionRangeKey.LAST_30_DAYS]: { period: 30 },
  [OptionRangeKey.LAST_60_DAYS]: { period: 60 },
  [OptionRangeKey.LAST_90_DAYS]: { period: 90 },
  [OptionRangeKey.LAST_180_DAYS]: { period: 180 },
  [OptionRangeKey.LAST_YEAR]: { period: 365 },
  [OptionRangeKey.OLDER_THAN_LAST_YEAR]: {
    offset: 365,
    startDate: moment('01-01-2018').tz(TIMEZONE_IDENTIFIER).utc(),
  },
};

/**
 * On the `value` field, the first item is the period (in days), and the second is the offset (in days).
 * An object would be a prettier alternative, but here I'm respecting `DropdownProps` type, which doesn't allow an object in the `value` field.
 */
const DateRange = [
  {
    text: 'Last 24 Hours',
    value: OptionRangeKey.LAST_24_HOURS,
  },
  {
    text: 'Last 7 Days',
    value: OptionRangeKey.LAST_7_DAYS,
  },
  {
    text: 'Last 14 Days',
    value: OptionRangeKey.LAST_14_DAYS,
  },
  {
    text: 'Last 30 Days',
    value: OptionRangeKey.LAST_30_DAYS,
  },
  {
    text: 'Last 60 Days',
    value: OptionRangeKey.LAST_60_DAYS,
  },
  {
    text: 'Last 90 Days',
    value: OptionRangeKey.LAST_90_DAYS,
  },
  {
    text: 'Last 180 Days',
    value: OptionRangeKey.LAST_180_DAYS,
  },
  {
    text: 'Last Year',
    value: OptionRangeKey.LAST_YEAR,
  },
  {
    text: 'Older than last year',
    value: OptionRangeKey.OLDER_THAN_LAST_YEAR,
  },
];

export class ActivityListFilter extends React.Component<
  ActivityListFilterProps,
  {}
> {
  @observable
  histConversations: ConversationModel[] = [];

  @action
  setConversationIds = (historyConversations: ConversationModel[]) =>
    (this.histConversations = historyConversations);

  constructor(props: ActivityListFilterProps) {
    super(props);
    makeObservable(this);
  }

  /**
   * Loads conversation history.
   * `offset` defines the end date, if different than now, in days.
   * `period` defines the period for the filter, in days.
   * `startDate` defines the start date, to define a specific start date instead of a period.
   */
  runHistory({ offset = 0, period, startDate }: OptionRange) {
    const now = moment().tz(TIMEZONE_IDENTIFIER).utc();

    let from: string;

    if (period) {
      from = now.clone().subtract(period, 'days').format().toString();
    } else {
      from = startDate.format().toString();
    }

    const to = now.subtract(offset, 'days').format().toString();

    const { conversation } = this.props;
    conversation
      .loadConversationHistoryBasedOnDateGet(to, from)
      .then((x) => this.setConversationIds(x as ConversationModel[]));
  }

  componentDidMount() {
    this.runHistory(OPTION_TO_RANGE[OptionRangeKey.LAST_7_DAYS]);
  }

  onChange = (e, d: DropdownProps) => {
    this.runHistory(OPTION_TO_RANGE[d.value as OptionRangeKey]);
  };

  get UnreadCounts() {
    const { conversation, selectUnreadCounts } = this.props;
    const convList = Array.from(conversation.AllConversations);
    const allUnreadCounts =
      convList.reduce<IUnreadMessageAndMentionCountsByConversationId>(
        (prev, next) => {
          prev[next.id] = selectUnreadCounts(next.id);
          return prev;
        },
        {}
      );
    return allUnreadCounts;
  }

  render() {
    const {
      conversation,
      currentConversationId,
      listUnreadFirst,
      loggedInAccountId,
      loggedInPersonId,
      makeCall,
      participant,
      selectConversationParticipants,
      selectParticipantPersonInfo,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      updateRoute,
      personStore,
    } = this.props;

    if (!conversation) {
      return <Loader active indeterminate size="mini" />;
    }

    let convItems: JSX.Element[];
    if (this.histConversations !== null && this.histConversations.length > 0) {
      const convList = this.histConversations;
      convItems = convList
        .slice()
        .sort((a, b) => {
          const aDate = a.lastMessageDate || a.created;
          const bDate = b.lastMessageDate || b.created;
          let sortResult = aDate <= bDate ? 1 : -1; // 1 is B before A, -1 is A before B
          if (listUnreadFirst) {
            const unreadCountsA = this.UnreadCounts[a.id];
            const unreadCountsB = this.UnreadCounts[b.id];
            const aHasUnreads =
              !isEmpty(unreadCountsA) && unreadCountsA.unreadMessages > 0;
            const bHasUnreads =
              !isEmpty(unreadCountsB) && unreadCountsB.unreadMessages > 0;
            if (aHasUnreads && !bHasUnreads) {
              sortResult = -1; // A before B
            } else if (!aHasUnreads && bHasUnreads) {
              sortResult = 1; // B before A
            } // ... Otherwise, leave the sort result alone
          }

          return sortResult;
        })
        .map((conv) => {
          return (
            <ActivityListItem
              conversation={conv}
              conversationId={conv.id}
              isCurrentConversation={currentConversationId === conv.id}
              loggedInAccountId={loggedInAccountId}
              loggedInPersonId={loggedInPersonId}
              makeCall={makeCall}
              key={conv.id}
              from={'history'}
              linkSuffix="history"
              loggedInUserActiveConferenceConversation={
                conversation.LoggedInUserActiveConferenceConversation
              }
              participant={participant}
              participantsPbo={selectConversationParticipants(conv.id)}
              postConferenceByConversationId={
                conversation.postConferenceByConversationId
              }
              selectParticipantPersonInfo={selectParticipantPersonInfo}
              selectPersonPresenceStatus={selectPersonPresenceStatus}
              updateRoute={updateRoute}
              selectUnreadCounts={selectUnreadCounts}
              setConversationAndTotalUnreadCount={
                setConversationAndTotalUnreadCount
              }
              updateMyLastReadMessage={updateMyLastReadMessage}
              personStore={personStore}
              conversationStore={conversation}
              setShowPersonDetails={personStore.setShowPersonDetails}
            />
          );
        });
    }

    return (
      <div id="activity-list-filter" className="flex-column flex-grow-shrink">
        {!conversation ? (
          <Loader id="activity-list-filter-loading" active indeterminate />
        ) : (
          <div
            id="activity-list-items-history-wrap"
            className="flex-column maxheightpercent"
          >
            <div id="history-range-wrap" className="flex-shrink">
              <Dropdown
                className="gray-bg-input"
                onChange={this.onChange}
                selectOnBlur={true}
                placeholder={DateRange[1].text}
                fluid
                selection
                options={DateRange}
              />
            </div>
            {convItems && (
              <div
                id="activity-list-items-history"
                className="flex-grow-shrink overflow-scroll-y activity-list-items-section"
              >
                <List inverted verticalAlign="middle" size="large" selection>
                  {convItems}
                </List>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default observer(ActivityListFilter);
