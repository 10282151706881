import { CALENDAR_IDS, CALENDAR_SCOPE, SHOW_CALENDAR } from 'Constants/env';
import React, { useCallback } from 'react';

import type { LoginResyncButtonProps } from './types';
import { NYLAS_V3 } from 'Constants/featureFlags';
import { Scope } from 'Models/Calendar';
import { Styled } from './index.styles';
import { handleMicrosoftLogIn } from 'Components/Microsoft';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import { useGoogleAuthLogin } from 'Components/GoogleAuthLogin/useGoogleAuthLoginHook';

export const LoginResyncButton = ({
  for: propsFor,
  person,
  provider,
  disabled,
}: LoginResyncButtonProps): JSX.Element => {
  const { [NYLAS_V3]: isUsingNylasV3 } = useFeatureFlags([NYLAS_V3]);
  const { handleLoginClick } = useGoogleAuthLogin({
    person,
    isUsingNylasV3: isUsingNylasV3,
  });
  const showCalendar =
    SHOW_CALENDAR ||
    (Array.isArray(CALENDAR_IDS) &&
      CALENDAR_IDS.includes(person.loggedInPersonId));

  const onMicrosoftLogin = (code: string) => {
    const scopesCode = ['contacts'] as Scope[];

    if (showCalendar) {
      scopesCode.push(CALENDAR_SCOPE);
    }

    person.sendCodeOfProvider(code, 'microsoft', scopesCode);
  };

  const handleProviderLogin = useCallback(() => {
    if (provider === 'microsoft') {
      handleMicrosoftLogIn({ propsFor, onLogin: onMicrosoftLogin });
    }

    if (provider === 'gmail') {
      handleLoginClick();
    }
  }, [handleLoginClick, propsFor, onMicrosoftLogin, provider]);

  return (
    <Styled.LoginButton onClick={handleProviderLogin} disabled={disabled}>
      Login
    </Styled.LoginButton>
  );
};
