import AddressBookPanel from 'Components/AddressBookPanel';
import Badge from 'Components/shared/Badge';
import { SecondaryMenu } from 'Components/shared/SecondaryMenu';
import { NYLAS_V3 } from 'Constants/featureFlags';
import {
  ADDRESS_BOOK_SUBMENU_OPTIONS,
  ADDRESS_BOOK_SUBPATHS,
} from 'Constants/routing';
import {
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import withRouter from 'Hocs/WithRouter';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { Grid } from 'semantic-ui-react';
import { hasStaleSources } from 'Utils/contactSyncSource';
import { IAddressBookContainerProps } from './interfaces';

const AddressBookContainer: React.FC<IAddressBookContainerProps> = ({
  ui,
  person,
  navigate,
  browserHistory,
}) => {
  const testid = 'addressBookContainer';

  const handleCloseDeletePopup = useCallback(() => {
    ui.setDeletePopupDisplay(false);
  }, [ui]);

  const handleCloseModals = useCallback(() => {
    handleCloseDeletePopup();
  }, [handleCloseDeletePopup]);

  const handleHeaderClick = useCallback(() => {
    const { pathname } = window.location;
    if (!pathname.includes('contact') || pathname.includes('contacts')) {
      navigate('/addressBook/contact');
    }

    person.setIsAddingContact(false);
    person.setEditContact(null);
    person.setShowPersonDetails(null, 'person');
  }, [navigate, person]);

  const { [NYLAS_V3]: isNylasV3FlagEnabled } = useFeatureFlags([NYLAS_V3]);
  console.log('isNylasV3FlagEnabled', isNylasV3FlagEnabled);
  const optionsWithBadges = useMemo(() => {
    const hasV2Sources = person.allSources?.some(
      (source) => !['google', 'microsoft', 'icloud'].includes(source.provider)
    );
    const isBadgeVisible =
      isNylasV3FlagEnabled && (hasV2Sources || hasStaleSources(person.allSources, ['icloud']));

    if (!isBadgeVisible) return ADDRESS_BOOK_SUBMENU_OPTIONS;

    return ADDRESS_BOOK_SUBMENU_OPTIONS.map((option) =>
      option.to === ADDRESS_BOOK_SUBPATHS.SOURCES
        ? { ...option, badge: <Badge level="warning" /> }
        : option
    );
  }, [person.allSources, isNylasV3FlagEnabled]);

  return (
    <Grid.Column
      className="settings-container flex-row"
      onClick={handleCloseModals}
    >
      {!ui.hideSecondaryMenu && (
        <SecondaryMenu
          text="+ Add new contact"
          options={optionsWithBadges}
          header={{
            onClick: handleHeaderClick,
            active:
              window.location.pathname.includes('contact') &&
              !window.location.pathname.includes('contacts'),
          }}
        />
      )}
      <AddressBookPanel browserHistory={browserHistory} {...{ testid }} />
    </Grid.Column>
  );
};

export default inject(
  STORE_UI,
  STORE_PREFERENCE,
  STORE_NOTIFICATION,
  STORE_PERSON
)(withRouter(observer(AddressBookContainer)));
