import { STORE_PERSON, STORE_UI } from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { SMALL_SCREEN_SIZE_BREAKPOINT } from '../../../constants/responsiveness';
import { SecondaryMenuOptionButton } from '../SecondaryMenuOptionButton';
import { Styled } from './index.styles';
import { SecondaryMenuProps } from './types';

const SecondaryMenuObserver: React.FC<SecondaryMenuProps> = ({
  person: { personAvaliableFeatures },
  ui: { setHideSecondaryMenu },
  header: { onClick, active } = { onClick: undefined, active: false },
  text,
  options,
}) => {
  const testid = 'secondarySideBarMenu';

  return (
    <Styled.SecondaryMenuWrapper
      onMouseLeave={() =>
        setHideSecondaryMenu(window.innerWidth < SMALL_SCREEN_SIZE_BREAKPOINT)
      }
    >
      <Styled.SecondaryMenuHeader>
        {onClick ? (
          <Styled.Button
            variant="primary"
            testid={`${testid}-buttonSecondaryMenuHeader`}
            content={text}
            {...{ onClick, active }}
          />
        ) : (
          <Styled.Heading variant="h5" noMargin>
            {text}
          </Styled.Heading>
        )}
      </Styled.SecondaryMenuHeader>
      <Styled.SecondaryMenuTabs>
        {options.map(({ to, text, featureSwitch, disabled, badge }) => (
          <SecondaryMenuOptionButton
            key={to}
            {...{ to, text, disabled, testid, badge }}
            {...(featureSwitch && {
              show: personAvaliableFeatures[featureSwitch],
            })}
          />
        ))}
      </Styled.SecondaryMenuTabs>
    </Styled.SecondaryMenuWrapper>
  );
};

export const SecondaryMenu = inject(
  STORE_PERSON,
  STORE_UI
)(observer(SecondaryMenuObserver));
