import type { SourceInformationProps } from './types';
import { NYLAS_V3 } from 'Constants/featureFlags';
import useWarningMessage from 'Hooks/contactSources/useWarningMessage';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import React from 'react';
import { isSourceStale } from 'Utils/contactSyncSource';
import { Styled } from './index.styles';

export const SourceInformation: React.FC<SourceInformationProps> = ({
  sourceType,
  sourceEmail,
  sourceState,
  mapProperLogoProvide,
  navigate,
}) => {
  const { [NYLAS_V3]: isNylasV3FlagEnabled } = useFeatureFlags([NYLAS_V3]);

  const isV3Source = ['google', 'microsoft'].includes(sourceType);

  const isV2Source = !isV3Source && !['bhive', 'icloud'].includes(sourceType);

  const showWarning = isNylasV3FlagEnabled && (sourceType === 'icloud' || isV2Source || isSourceStale(sourceState));

  const warningMessage = useWarningMessage(showWarning, sourceType, isV2Source, sourceState);

  return (
    <Styled.Container>
      <Styled.Label>{'Source'}</Styled.Label>

      <Styled.InfoWrapper isSourceStale={showWarning}>
        <Styled.Logo src={mapProperLogoProvide(sourceType, true)} />
        <Styled.Email>{sourceEmail}</Styled.Email>
      </Styled.InfoWrapper>

      {showWarning && (
        <Styled.WarningContainer>
          <Styled.WarningIcon icon="warning-filled" />
          <Styled.Warning>
            <Styled.WarningTitle>{'Warning: '}</Styled.WarningTitle>
            <Styled.WarningText>
              {warningMessage}
            </Styled.WarningText>
          </Styled.Warning>

          <Styled.ManageSourcesButton
            onClick={() => {
              navigate('/addressBook/sources');
            }}
            testid="contacts-sourceInformation"
            variant="primary"
          >
            {'Manage sources'}
          </Styled.ManageSourcesButton>
        </Styled.WarningContainer>
      )}
    </Styled.Container>
  );
};
