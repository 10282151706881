import { ISourceAccount, Provider } from 'Interfaces/SourceAccount';

/**
 * Check if the source is stale if the syncState is one of the following:
 * invalid-credentials / invalid / exception / sync-error / stopped
 * @param {string} source
 * @returns {boolean}
 */
export const isSourceStale = (source: string) => {
  return [
    'invalid-credentials',
    'invalid',
    'exception',
    'sync-error',
    'stopped',
  ].includes(source);
};

/**
 * Check if any of the sources are stale.
 * Calls {@link isSourceStale} to determine if a source is stale.
 * @param {ISourceAccount[]} sources - The array of source accounts to check.
 * @param {string[]} [ignoreProviders=[]] - The array of providers to ignore.
 * @returns {boolean} True if any of the sources are stale, false otherwise.
 * @see isSourceStale
 */
export const hasStaleSources = (
  sources: ISourceAccount[],
  ignoreProviders: Provider[] = []
) => {
  return sources.some((source) => {
    return (
      !ignoreProviders.includes(source.provider) &&
      isSourceStale(source?.syncState)
    );
  });
};
