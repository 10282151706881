import styled from 'styled-components';
import { Icon } from '../shared/Icon';
import { SideBarMenuButton } from './SideBarMenuButton';

const Drawer = styled.div<{ $isOpen?: boolean }>`
  display: none;
  @media only screen and (max-height: 700px) {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 100%;
    display: flex;
    position: fixed;

    left: 70px;
    z-index: 110;
    background-color: ${({ theme }) => theme.colors.base};
    box-shadow: inset 4px 6px 8px 0px rgba(0, 0, 0, 0.1);
    transition: ${({ theme }) => theme.transitions.ease};
    padding: 0 !important;
    &:after {
      content: '';
      width: 3px;
      background: #f7f7f7;
      box-shadow: 0px 3px 6px #00000029;
    }
    ${({ $isOpen }) =>
      $isOpen &&
      `
        width: 270px;
        overflow: visible;
        visibility: visible;
        opacity: 1;
    `}
  }
`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 15px;
  padding: 30px 20px;
`;

const StyledSideBarMenuButton = styled(SideBarMenuButton)`
  &:before {
    height: 4px;
    width: 100%;
    top: unset;
    bottom: 0;
  }
`;

const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -13px;
  top: 64px;
  background: ${({ theme }) => theme.colors.base};
  border: 4px solid #f7f7f7;
  border-radius: 100%;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  display: flex;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Styled = {
  Drawer,
  Container,
  SideBarMenuButton: StyledSideBarMenuButton,
  CloseButton,
  Icon: StyledIcon,
};
