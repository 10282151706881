import { Icon } from 'Components/shared/Icon';
import styled from 'styled-components';

const SourceItem = styled.div`
  padding: 14px 0px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightMediumGray};
  position: relative;

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightMediumGray};
  }
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
`;

const SourceItemActions = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 10px;
  top: 29px;
`;

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Show = styled.span`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  &:hover {
    cursor: pointer;
    color: black;
  }
`;

const ProviderLogo = styled.img`
  width: 15px;
  height: 15px;
`;

const WarningContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const WarningIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.warning} !important;
`;

const Warning = styled.div`
  font-size: 12px;
  line-height: 18px;
`;

const WarningTitle = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.warning};
`;

const WarningText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Styled = {
  SourceItem,
  Content,
  SourceItemActions,
  Contact,
  Show,
  ProviderLogo,
  WarningContainer,
  WarningIcon,
  Warning,
  WarningTitle,
  WarningText,
};
