import styled from 'styled-components';

const Dot = styled.div<{ $color: string }>`
  width: 10px; // 10px = 8px content + 2px border
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  border: 1px solid white;
`;

const Styled = {
  Dot,
};

export default Styled;
