import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

const SidebarMenu = styled.div`
  max-width: 70px;
  width: 100%;
  max-height: calc(${({ theme }) => theme.deviceSize.height} - 70px);
  height: calc(${({ theme }) => theme.deviceSize.height} - 70px);
  background: ${({ theme }) => theme.colors.base};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 !important;
  position: fixed !important;

  left: 0;
  z-index: 2;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  & + div {
    margin-left: 70px;
  }
`;

const TopSidebarMenu = styled.div``;

const BottomSidebarMenu = styled.div``;

const Column = styled(Grid.Column)<{ $isActive: boolean }>`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 4px;
  position: relative;
  &:before {
    content: '';
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: ${({ theme }) => theme.transitions.ease};
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    span {
      font-weight: bold;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    `
      &:before{
        opacity: 1;
        visibility: visible;
      }
  `}
`;

const Label = styled.span<{ $isActive?: boolean }>`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1em;

  ${({ $isActive }) =>
    $isActive &&
    `
      font-weight: bold;
  `}
`;

const TouchSidebar = styled.div<{ $renderTouchSidebar?: boolean }>`
  position: absolute;
  z-index: 140;
  height: inherit;
  width: inherit;

  ${({ $renderTouchSidebar }) =>
    !$renderTouchSidebar &&
    `
      display:none;
  `};
`;

export const Styled = {
  SidebarMenu,
  TopSidebarMenu,
  BottomSidebarMenu,
  Column,
  Label,
  TouchSidebar,
};
