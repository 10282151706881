import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BV_ENV, RELEASE_ID } from '../constants/env';

export const bugsnagClient = Bugsnag.createClient({
  apiKey: '2b256148567da4202f392d7b065d5e00',
  appType: 'web_core',
  appVersion: RELEASE_ID,
  releaseStage: window.location.hostname === 'localhost' ? 'local' : BV_ENV,
  maxBreadcrumbs: 40,
  enabledBreadcrumbTypes: [
    'error',
    'manual',
    'navigation',
    'process',
    'request',
    'state',
    'user',
  ], // 'log' (console) breadcrumbs disabled
  onBreadcrumb: function (breadcrumb) {
    if (breadcrumb.type === 'request') {
      const analytics = [
        'https://www.google-analytics.com',
        'https://stats.g.doubleclick.net',
        'https://x.clarity.ms',
      ];
      const isAnalyticsNetworkBreadcrumb = analytics.some((analyticsUrl) => {
        return breadcrumb.metadata?.request?.includes(analyticsUrl)
          ? true
          : false;
      });
      return isAnalyticsNetworkBreadcrumb ? false : true;
    }
    return true;
  },
  plugins: [new BugsnagPluginReact()],
});
