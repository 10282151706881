import { UserAvatar } from 'Components/shared/UserAvatar';
import styled from 'styled-components';

const EntityProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledUserAvatar = styled(UserAvatar)`
  width: 80px;
  height: 80px;
  font-size: 18px;
`;

const DisplayName = styled.div`
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  margin-top: 18px;
`;

const UserStatus = styled.span`
  margin-left: 1rem;
  color: black;
`;

const ChildrenSlot = styled.div`
  margin-top: 16px;
`;

export const Styled = {
  EntityProfileHeader,
  UserAvatar: StyledUserAvatar,
  DisplayName,
  UserStatus,
  ChildrenSlot,
};
