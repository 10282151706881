import type { BadgeProps } from './index.types';
import React from 'react';
import { theme } from '~/styles/theme/Theme';
import Styled from './index.styles';

const levels = {
  info: theme.colors.primary,
  success: theme.colors.success,
  warning: theme.colors.warning,
  danger: theme.colors.danger,
};

/**
 * Badge component that displays a dot with a specified color based on the level prop.
 *
 * @component
 * @param {BadgeProps} props - The properties for the Badge component.
 * @param {'info' | 'warning' | 'error' | 'success'} [props.level='info'] - The level of the badge which determines its color.
 * @param {React.ReactNode} props.children - The content to be displayed inside the badge.
 * @returns {JSX.Element} The rendered Badge component.
 */
const Badge: React.FC<BadgeProps> = ({
  level = 'info',
  children,
  ...props
}) => {
  const color = levels[level] || theme.colors.primary;

  return (
    <Styled.Dot $color={color} {...props}>
      {children}
    </Styled.Dot>
  );
};

export default Badge;
