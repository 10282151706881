import {
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import ActivityListFilter from '../../components/ActivityListFilter';
import withRouter from '../../hocs/WithRouter';
import { pushToGTMDataLayer } from '../../utils/analytics';
import { resolveConversationPath } from '../../utils/routeNav';
import { HistoryListProps } from './interfaces';

export class HistoryList extends React.Component<HistoryListProps, {}> {
  updateRoutes = (path) => {
    const { navigate, location } = this.props;
    const newPath = resolveConversationPath(location.pathname, path);
    navigate(newPath);
  };

  makeCall = (personId?: number, phone?: string) => {
    const { phoneCall } = this.props;
    if (personId) {
      phoneCall.callWithPerson(personId, null);
    } else if (phone) {
      phoneCall.callWithPerson(null, phone);
    } else {
      throw new Error('Either Phone Number or Person Id is required.');
    }
  };

  UNSAFE_componentWillMount() {
    const { conversation } = this.props;
    // When this component is mounted, load up the `Conversation` (and favorites) lists if they aren't already loading (ex. logged in via persisted localforage token)
    conversation.loadConversationsConditionallyGet(false);
    conversation.loadFavoriteConversationsConditionallyGet(false);
    pushToGTMDataLayer('historyList');
  }

  render() {
    const { conversation, participant, person, params, preference, ui } =
      this.props;
    return (
      <ActivityListFilter
        conversation={conversation}
        conversationId={params.conversationId}
        currentConversationId={params.conversationId}
        listUnreadFirst={preference.preferences.listUnreadFirst}
        loggedInAccountId={person.loggedInAccountId}
        loggedInPersonId={person.loggedInPersonId}
        makeCall={this.makeCall}
        participant={participant}
        selectConversationParticipants={
          participant.selectParticipantsByConversationId
        }
        selectOtherConversationParticipants={
          participant.selectOtherParticipants
        }
        selectParticipantPersonInfo={person.loadPersonByIdGetIfMissingGet}
        selectPersonPresenceStatus={ui.selectPersonPresenceStatus}
        selectUnreadCounts={ui.selectConversationUnreadCounts}
        setConversationAndTotalUnreadCount={
          ui.setConversationAndTotalUnreadCount
        }
        updateMyLastReadMessage={participant.updateMyLastReadMessage}
        updateRoute={this.updateRoutes}
        personStore={person}
      />
    );
  }
}

export default inject(
  STORE_CONVERSATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PREFERENCE,
  STORE_PHONE_CALL,
  STORE_UI
)(withRouter(observer(HistoryList)));
