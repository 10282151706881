import { Contact } from 'Models/Contacts';
import React from 'react';
import { Styled } from './index.styles';

type ExternalContactAliasProps = {
  extrContacts: Contact[];
  editExtContacts: (
    id: number,
    phoneNumbers: string[]
  ) => (e: React.MouseEvent) => void;
};
export default function ExternalContactAlias({
  extrContacts,
  editExtContacts,
}: ExternalContactAliasProps) {
  return (
    <Styled.Container>
      {extrContacts.map((contact, index) => {
        if (index < 1) return null;

        return (
          <Styled.ContactItem key={contact.id}>
            <div>Same phone number on</div>
            <Styled.ContactName>{contact.DisplayName()}</Styled.ContactName>
            <Styled.EditContactLink
              role="link"
              onClick={editExtContacts(
                contact.id,
                contact.phoneNumbers as string[]
              )}
            >
              Edit Contact
            </Styled.EditContactLink>
          </Styled.ContactItem>
        );
      })}
    </Styled.Container>
  );
}
