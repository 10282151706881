import { BodyCopy } from 'Components/shared/BodyCopy';
import styled from 'styled-components';

const SourceList = styled.div`
  width: 100%;
  position: relative;
`;

const SourceWrapper = styled.div`
  margin-top: 17px;
`;

const Disclaimer = styled(BodyCopy)`
  text-align: center;
  max-width: 544px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 53px;
`;

const RemoveNotification = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  padding: 15px 58px;
  max-width: 743px;
  min-width: 307px;
  width: 50%;
  margin: 0 auto;
  transform: translateX(-5%);
  background-color: ${({ theme }) => theme.colors.warning};
  color: ${({ theme }) => theme.colors.base};
  position: absolute;
  top: 1px;
  height: 50px;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.sidebar};
`;

const SourceBoxes = styled.div`
  margin-top: 25px;
  margin-bottom: 35px;
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
`;

export const Styled = {
  SourceList,
  SourceWrapper,
  Disclaimer,
  RemoveNotification,
  Title,
  SourceBoxes,
};
