import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding-top: 40px;
  width: 100%;
`;

const ShareUrlInput = styled.input`
  display: flex;
  align-self: stretch;
  margin: 10px;
  max-width: 100%;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  background: #2b2b2b;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #3cc697;
  border-radius: 0.28571429rem;

  &:focus {
    background: white;
  }
`;

export const Styled = {
  Container,
  ShareUrlInput,
};
