import type { SystemMessageProps } from './SystemMessageWrapper.types';
import { useEnumerateSystemMessageParticipants } from 'Components/Chat/hooks/useEnumerateSystemMessageParticipants';
import * as React from 'react';
import { SystemMessageWrapper } from './SystemMessageWrapper';

export const SystemMessageAddUser: React.FC<SystemMessageProps> = ({
  message,
}) => {
  const { formattedAuthor, formattedParticipants } =
    useEnumerateSystemMessageParticipants(
      message.systemEvent.reference,
      message?.systemEvent?.payload?.participantsAdded
    );

  return (
    <SystemMessageWrapper
      created={message.created}
      icon="add-user"
      title={[
        formattedAuthor,
        ' added ',
        formattedParticipants,
        ' to the group',
      ].map((titleItem, index) => (
        <React.Fragment key={index}>{titleItem}</React.Fragment>
      ))}
    />
  );
};
