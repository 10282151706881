import closeBtn from 'Assets/images/close-btn.svg';
import cx from 'classnames';
import { CALENDAR_IDS, SHOW_CALENDAR, VIDEO_URL } from 'Constants/env';
import {
  STORE_CALENDAR,
  STORE_CONVERSATION,
  STORE_PERSON,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import type { SearchResponseData } from 'Stores/SearchStore.types';
import { debounce, isEmpty, uniqBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import { initialCreatedEvent } from 'Models/Calendar';
import {
  INewConference,
  IPrivateConversationConference,
} from 'Models/ConversationModel';
import moment, { Moment } from 'moment';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import {
  Button,
  Dropdown,
  DropdownProps,
  Form,
  Grid,
  Icon,
  InputProps,
  Modal,
  Radio,
} from 'semantic-ui-react';
import { CalendarStore } from 'Stores/CalendarStore';
import type {
  ConversationStore,
  PersonStore,
  SearchStore,
  UiStore,
} from 'Stores/index';
import { CustomDateTimeComponent } from '../../CalendarEvent/CalendarComponents';
import RoomPin from '../../RoomPin';
import { QuestionHelper } from '../../shared/QuestionHelper';
import { CopyComponent } from '../../VideoComponent/CopyComponent';
import { TrialExpDate } from '../../VideoComponent/VideoMenu/MyRoom';

let timeout: any;

export interface IGroupMembers {
  key: string;
  text: string;
  value: string;
}

interface NewRoomProps {
  /* injected */
  search?: SearchStore;
  conversation: ConversationStore;
  ui: UiStore;
  person: PersonStore;
  calendar: CalendarStore;
}

interface NewRoomState {
  showSuccessMessage: boolean;
  type: 'AdHocMeetNow' | 'AdHocScheduled';
  link: string;
  copied: boolean;
  hasMoreData: boolean;
  creatorName: string;
  showMorePhoneNumbers: boolean;
  creatingMeeting: boolean;
  joinByPhone: {
    pin?: string;
    dialInInfos?: { number: string }[];
  };
  laodingContacts: boolean;
  startDate: Moment;
  endDate: Moment;
  startTime: Moment;
  endTime: Moment;
  searchInput: string;
  allUsersSelected: SearchResponseData['results'];
}

const initialState: INewConference & NewRoomState = {
  displayName: '',
  invitees: [],
  visibility: 'Public',
  provider: 'bhive',
  showSuccessMessage: false,
  shouldStart: false,
  type: 'AdHocScheduled',
  link: '',
  copied: false,
  hasMoreData: true,
  textToCopy: [],
  creatorName: '',
  creatingMeeting: false,
  showMorePhoneNumbers: false,
  joinByPhone: {
    pin: undefined,
    dialInInfos: [],
  },
  laodingContacts: false,
  startDate: moment(),
  endDate: moment(),
  startTime: moment(),
  endTime: moment().add(15, 'minute'),
  searchInput: '',
  allUsersSelected: [],
};
class NewRoom extends React.Component<
  NewRoomProps,
  INewConference & NewRoomState
> {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  directorySearchThrottled = debounce(
    this.props.search.getDirectorySearch,
    100,
    { leading: false }
  );

  UNSAFE_componentWillMount() {
    this.directorySearchThrottled('USERS');
  }

  handleChangeVisibility = (e, { value }) =>
    this.setState({ visibility: value });

  handleChangeShouldStart = (e, { value }) => this.setState({ type: value });

  handleNameInsertion = ({ currentTarget }) =>
    this.setState({ displayName: currentTarget.value });

  handleOnChangeDropdown = (e, d: DropdownProps) => {
    const { search } = this.props;
    const directory = search.directoryMap?.['USERS'];
    const filteredResults = directory?.data?.results;
    const filteredResultsCheckbox = filteredResults?.filter(
      (person) =>
        !!person.label &&
        (d.value as string[]).includes(person?.source?.id?.toString())
    );
    const mergedPrevCurrent = uniqBy(
      [...this.state.allUsersSelected, ...filteredResultsCheckbox],
      (person) => person?.source?.id
    );
    this.setState(
      {
        invitees: d.value as string[],
        allUsersSelected: mergedPrevCurrent,
        searchInput: '',
      },
      () => this.handleFocus(mergedPrevCurrent)()
    );
  };

  appendDateTime = (date: Moment, time: Moment) => {
    return date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: 0,
      millisecond: 0,
    });
  };

  getPersonsById = async () => {
    const allPersonsPbo = this.state.invitees.map((id) =>
      this.props.person.loadPersonByIdGetIfMissingGet(id)
    );
    const allPersonsResp = await Promise.all(allPersonsPbo);
    return allPersonsResp.map((personResp) => personResp.data);
  };

  createEvent = async (
    conference: IPrivateConversationConference,
    creatorName: string
  ) => {
    const { startTime, startDate, endTime, endDate } = this.state;
    const event = { ...initialCreatedEvent };
    const personExtrContacts = (await this.getPersonsById()) || [];
    const startD = this.appendDateTime(startDate, startTime);
    const endD = this.appendDateTime(endDate, endTime);
    event.title = this.state.displayName;
    event.startDate = startD.toLocaleString();
    event.endDate = endD.toLocaleString();
    event.conferencing = this.props.calendar.mapToConferecingData(
      conference
    ) as any;
    event.participants = this.props.calendar.mapParticipantsToMatchIEvent(
      this.props.person.loggedInEmail,
      personExtrContacts,
      false
    );
    event.description = this.props.calendar.createEventDescription(
      conference?.displayName,
      conference?.id,
      creatorName
    );
    this.props.calendar.createEvent(event);
  };

  handleSave = (showCalendar: boolean) => async () => {
    this.setState({ creatingMeeting: true });
    const { conversation, person } = this.props;
    const data: INewConference = { ...this.state } as INewConference;
    data.invitees = data.invitees.map((personId) => {
      return { personId };
    });
    data.shouldStart = this.state.type === 'AdHocMeetNow';
    const creator = await person.loadPersonByIdGetIfMissingGet(
      person.loggedInPersonId
    );
    const respConference = await conversation.postNewConference(data);
    this.setState({ creatingMeeting: false });
    if (respConference) {
      const name = creator.data.firstName;
      showCalendar && this.createEvent(respConference.data, name);
      const text = [
        `${name} is inviting you to a B-hive Video meeting ${
          this.state.displayName || ''
        }`,
        'Join meeting on',
        VIDEO_URL + respConference.data.id,
      ];
      const dialNumber =
        respConference.data?.dialInInfos?.length > 0
          ? formatPhoneNumberIntl(respConference?.data?.dialInInfos[0]?.number)
          : '';
      const pin = respConference.data?.pin?.match(/.{1,3}/g).join(' ') || '';
      this.setState({
        showSuccessMessage: true,
        link: VIDEO_URL + respConference.data.id,
        textToCopy: [...text, `\nDial in: ${dialNumber}\nPIN: ${pin}`],
        creatorName: name,
        joinByPhone: {
          pin: respConference.data?.pin,
          dialInInfos: respConference.data?.dialInInfos,
        },
      });
      if (!data.shouldStart) {
        conversation.toogleLinkModal();
      } else {
        this.setState(initialState);
      }
    }
  };

  handleModalClose = () => {
    this.props.conversation.toogleLinkModal();
    this.setState(initialState);
  };

  handleCopy = () => {
    const { ui } = this.props;
    ui.copyToClipboard(this.state.textToCopy);
    this.setState({ copied: true });
  };

  handleLoadContacts = async () => {
    const {
      ui: { handleLoadMoreContacts },
    } = this.props;
    if (this.state.hasMoreData && !this.state.laodingContacts) {
      this.setState({ laodingContacts: true });
      const resp: boolean = await handleLoadMoreContacts(false, true);
      resp
        ? this.setState({ hasMoreData: false, laodingContacts: false })
        : this.setState({ laodingContacts: false });
    }
  };

  handleScrollDown = () => {
    timeout = setTimeout(() => {
      const element: any = document.querySelector(
        '#inviteGroup > div.visible.menu.transition'
      );
      element.onscroll = (e: any) => {
        const bottom =
          e.target.scrollHeight -
          e.target.scrollTop -
          e.target.clientHeight -
          10;
        if (bottom <= 20 && this.state.hasMoreData) {
          this.handleLoadContacts();
        }
      };
    }, 500);
    if (!this.state.hasMoreData) {
      clearTimeout(timeout);
    }
  };

  handleShowMorePhoneNumbers = () => {
    this.setState({
      ...this.state,
      showMorePhoneNumbers: !this.state.showMorePhoneNumbers,
    });
  };

  refreshData = () => {
    const { ui } = this.props;
    ui.setTopBarSearchValue('');
    this.setState({ hasMoreData: true });
  };

  filter = debounce((inputProps: InputProps) => {
    const { search, ui } = this.props;
    search.getDirectorySearch('USERS', inputProps.searchQuery);
    ui.setTopBarContactPageNumber(1);
  }, 300);

  onSearch = (e, inputProps: InputProps) => {
    this.setState({ hasMoreData: true, searchInput: inputProps.searchQuery });
    this.filter(inputProps);
  };

  handleStartDatePick = (date: Date) =>
    this.setState({ startDate: moment(date), endDate: moment(date) });

  handleStartTimePick = (date: Date) => {
    const { endTime } = this.state;
    const selectedDate = moment(date);
    const diff = this.props.ui.dateDiff(selectedDate, endTime);
    this.setStartEndDate(diff, date, 'add');
  };

  handleEndTimePick = (date: Date) => {
    const { startTime } = this.state;
    const selectedDate = moment(date);
    const diff = this.props.ui.dateDiff(selectedDate, startTime);
    this.setStartEndDate(diff, date, 'substract');
  };

  setStartEndDate = (
    diff: number,
    date: Date,
    operationType: 'add' | 'substract'
  ) => {
    //prevent setting endDate earlier than startDate ( both ways )
    let startEnd = {};
    if (operationType === 'add') {
      startEnd =
        diff > 0
          ? {
              startTime: moment(date),
              endTime: moment(date).add(15, 'minute'),
            }
          : {
              startTime: moment(date),
            };
    } else if (operationType === 'substract') {
      startEnd =
        diff < 0
          ? {
              startTime: moment(date).subtract(15, 'minute'),
              endTime: moment(date),
            }
          : {
              endTime: moment(date),
            };
    }
    this.setState({ ...startEnd });
  };

  handleFocus = (options) => () => {
    const { searchInput } = this.state;
    if (!searchInput && options?.length < 20) {
      this.directorySearchThrottled('USERS');
    }
    (document.querySelector('#inviteGroup input') as HTMLInputElement).focus();
  };

  LinkModal = () => {
    const {
      conversation: { showLinkModal },
      ui,
    } = this.props;
    const { creatorName, displayName, copied, link, joinByPhone } = this.state;
    const dialNumber =
      joinByPhone?.dialInInfos?.length > 0
        ? formatPhoneNumberIntl(joinByPhone?.dialInInfos[0]?.number)
        : '';

    return (
      <Modal
        open={showLinkModal}
        size="small"
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={this.handleModalClose}
        className="link-modal"
        dimmer="blurring"
      >
        <Modal.Header>
          <span>Here’s the link to your meeting</span>
          <img
            className="close-btn pointer"
            src={closeBtn}
            onClick={this.handleModalClose}
          />
        </Modal.Header>
        <Modal.Content className="msg-group-dump-content" scrolling>
          <div>
            Copy this link and send it to people you want to meet with. Make
            sure you save this link so you can use it later.
          </div>
          <div className="link-box">
            <div className="text">
              <span>
                <b>{creatorName}</b> is inviting you to a B-hive Video meeting{' '}
                <b>{displayName}</b>.
              </span>
              <span>Join meeting on </span>
              <span className="link">{link}</span>
              <div className="flex-column">
                <span className="flex-row">
                  <b>Dial-in:&nbsp;</b> {dialNumber}
                </span>
                <span className="flex-row">
                  <b>PIN:</b>
                  <RoomPin pin={joinByPhone?.pin} />
                </span>
                {joinByPhone?.dialInInfos?.length > 1 && (
                  <div className="flex-column margin-top-1rem">
                    <span
                      className="toggle-more-phones"
                      onClick={this.handleShowMorePhoneNumbers}
                    >
                      {this.state.showMorePhoneNumbers
                        ? 'Less phone numbers'
                        : 'More phone numbers'}
                    </span>
                    {this.state.showMorePhoneNumbers && (
                      <div className="flex-column">
                        {joinByPhone?.dialInInfos?.slice(1).map((el, index) => (
                          <div key={`number-${el.number}-${index}`}>
                            <span className="flex-row">{el.number}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <CopyComponent
              ui={ui}
              hovered={false}
              copied={copied}
              handleCopy={this.handleCopy}
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  render() {
    //need showLinkModal for rerendering
    const {
      search,
      ui,
      person,
      conversation: { showLinkModal },
    } = this.props;
    const {
      invitees,
      searchInput,
      laodingContacts,
      showSuccessMessage,
      type,
      visibility,
      displayName,
      creatingMeeting,
      startDate,
      startTime,
      endTime,
    } = this.state;
    const directory = search.directoryMap?.['USERS'];
    const filteredResults = directory?.data?.results;
    const filteredResultsCheckbox = filteredResults?.filter(
      (person) => !!person.label
    );
    const mergedResults =
      filteredResultsCheckbox &&
      uniqBy(
        [...this.state.allUsersSelected, ...filteredResultsCheckbox],
        (person) => person?.source?.id
      );
    const showCalendar =
      SHOW_CALENDAR || CALENDAR_IDS?.includes(person.loggedInPersonId);
    const today = moment();
    return (
      <div className="create-new-room">
        <div className="title-room">Create a New Meeting</div>
        <Form>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="meeting-settings">MEETING SETTINGS</div>
                <Radio
                  label="Instant Meeting"
                  name="shouldStart"
                  value="AdHocMeetNow"
                  checked={type === 'AdHocMeetNow'}
                  onChange={this.handleChangeShouldStart}
                  className="radio-btn"
                />
                <span className="light-text">
                  Starts the meeting immediately.
                </span>
                <Radio
                  label="Create a meeting for later"
                  name="shouldStart"
                  onChange={this.handleChangeShouldStart}
                  checked={type === 'AdHocScheduled'}
                  value="AdHocScheduled"
                  className="radio-btn"
                />
                <span className="light-text">
                  Generates a shareable meeting link for future use.
                </span>
              </Grid.Column>
              <Grid.Column>
                <div className="room-type">ROOM TYPE</div>
                <Radio
                  label="Public"
                  name="visibility"
                  value="Public"
                  checked={visibility === 'Public'}
                  onChange={this.handleChangeVisibility}
                  className="radio-btn"
                />
                <span className="light-text">
                  Any authenticated account member can join
                </span>
                <Radio
                  label="Private"
                  name="visibility"
                  value="Private"
                  checked={visibility === 'Private'}
                  onChange={this.handleChangeVisibility}
                  className="radio-btn"
                />
                <span className="light-text">
                  Non invited users need confirmation to join.
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {showCalendar && (
            <div className="event-input">
              <div className="field-title">TIME</div>
              <div className="flex-row">
                <div
                  className={cx('time-section', {
                    'disabled-datepicker': type === 'AdHocMeetNow',
                  })}
                >
                  <DatePicker
                    readOnly={type === 'AdHocMeetNow'}
                    selected={startDate.toDate()}
                    onChange={this.handleStartDatePick}
                    id="startDate"
                    wrapperClassName="flex-row"
                    dateFormat="MMMM dd"
                    minDate={moment().toDate()}
                    customInput={<CustomDateTimeComponent />}
                  />
                  <DatePicker
                    readOnly={type === 'AdHocMeetNow'}
                    selected={startTime.toDate()}
                    onChange={this.handleStartTimePick}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    wrapperClassName="time-component flex-row"
                    id="startTime"
                    minTime={
                      startDate.format('YYYY:MM:DD').toString() ===
                        today.format('YYYY:MM:DD').toString() &&
                      today.hours() > 6
                        ? today.toDate()
                        : today.clone().set('hours', 6).toDate()
                    }
                    maxTime={today.clone().endOf('day').toDate()}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    customInput={<CustomDateTimeComponent isTime />}
                  />
                  <span className="divider"> - </span>
                  <DatePicker
                    readOnly={type === 'AdHocMeetNow'}
                    selected={endTime.toDate()}
                    onChange={this.handleEndTimePick}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={
                      startDate.format('YYYY:MM:DD').toString() ===
                        today.format('YYYY:MM:DD').toString() &&
                      startTime.hours() > 6
                        ? startTime.clone().add(15, 'minute').toDate()
                        : today.clone().set('hours', 6).toDate()
                    }
                    maxTime={today.clone().endOf('day').toDate()}
                    wrapperClassName="time-component end-time flex-row"
                    id="endTime"
                    className="time"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="hh:mm aa"
                    customInput={<CustomDateTimeComponent isTime />}
                  />
                </div>
              </div>
            </div>
          )}
          <Form.Field className="margin-top-2rem">
            <Form.Input
              data-private
              className="input-field"
              fluid
              label="MEETING TITLE"
              placeholder="My New Meeting"
              value={displayName}
              onChange={this.handleNameInsertion}
              id="displayName"
            />
          </Form.Field>
          <Form.Field className="invite-section">
            <div className="flex-row flex-justify-start flex-align-items-center">
              <span className="invite-label">INVITE USERS</span>
              <QuestionHelper
                testid="-inviteUsers"
                content="Invited users will be able to join the meeting without confirmation. You are still responsible to send them the meeting link."
              />
            </div>
            <Dropdown
              placeholder="ex. John Doe"
              icon={
                isEmpty(invitees) && (
                  <Icon name="search" color="grey" className="search-icon" />
                )
              }
              className="invite-group"
              id="inviteGroup"
              fluid
              multiple
              searchQuery={searchInput}
              search
              selection
              loading={laodingContacts}
              value={invitees}
              options={mergedResults as IGroupMembers[]}
              onChange={this.handleOnChangeDropdown}
              onOpen={this.handleScrollDown}
              onSearchChange={this.onSearch}
              onClose={this.refreshData}
              onClick={this.handleFocus(mergedResults)}
            />
          </Form.Field>
        </Form>
        <div className="flex-row flex-align-items-center new-room-btn">
          <Button
            id="start-meeting"
            content="NEW MEETING"
            disabled={creatingMeeting}
            className="flex-grow-shrink"
            onClick={this.handleSave(showCalendar)}
          />
        </div>
        {person.personAvaliableFeatures.video.enabled &&
          person.personAvaliableFeatures.video.expirationDate && (
            <TrialExpDate ui={ui} person={person} />
          )}
        {<this.LinkModal />}
      </div>
    );
  }
}
export default inject(
  STORE_SEARCH,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PERSON,
  STORE_CALENDAR
)(observer(NewRoom));
