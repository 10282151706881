import { ChatLayoutView } from 'Components/Chat/ChatLayout/ChatLayoutView';
import ContextContentItemsList from 'Components/ContextContentItemsList';
import PrintMessageHeader from 'Components/ContextContentItemsList/PrintMessageHeader';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PUSHER,
  STORE_UI,
} from 'Constants/stores';
import withRouter from 'Hocs/WithRouter';
import { usePreferences } from 'Hooks/preferences/usePreferences';
import { MessagesGetRequest } from 'Interfaces/apiDtos';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { get, isEmpty } from 'lodash';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { IPromiseBasedObservable } from 'mobx-utils';
import { ContactModelBase } from 'Models/ContactModel';
import { PersonModel } from 'Models/PersonModel';
import React, { useCallback, useEffect } from 'react';
import { isNullOrUndefined } from 'util';
import { resolveConversationPath } from 'Utils/routeNav';
import { bugsnagClient } from '../../utils/logUtils';
import { Styled } from './index.styles';
import { ContextContentProps } from './interfaces';

const ContextContent: React.FC<ContextContentProps> = ({
  conversation,
  notification,
  ui,
  params,
  message,
  person,
  pusher,
  participant,
  phoneCall,
  contact,
  location,
}) => {
  const { conversationId } = params;

  const resolveConversationLinkPath = useCallback(
    (path: string) => resolveConversationPath(location.pathname, path),
    [location.pathname]
  );

  const loadParticipantsAndMessages = useCallback(
    action(
      async (
        conversationId: string,
        messagesGetRequest?: MessagesGetRequest
      ) => {
        try {
          await person.waitUntilLoggedIn();
          await pusher.waitUntilPusherConnected();
          await pusher.waitUntilPersonalChannelSubscribed();

          return await message.loadConversationMessagesIfMissingGet(
            conversationId,
            messagesGetRequest
          );
        } catch (error) {
          bugsnagClient.notify(error, (event) => {
            event.severity = 'error';
            event.context = 'ContextContent';
            event.addMetadata('custom', {
              function: 'loadParticipantsAndMessages',
            });
          });
        }
      }
    ),
    [message, person, pusher]
  );

  const makeCall = useCallback(
    (personId?: number, phone?: string) => {
      phoneCall.callWithPerson(personId, phone);
    },
    [phoneCall]
  );

  const toggleEmojiPicker = useCallback(() => {
    ui.setEmojiPickerState({ open: false, editing: false });
  }, [ui]);

  const { preferences } = usePreferences();

  useEffect(() => {
    void (async () => {
      await loadParticipantsAndMessages(conversationId, {
        Limit: 31,
        SortDirection: 'Descending',
        ShowDeleted: true,
        ShowCallMessagesInChat: preferences.showCallMessagesInChat,
      });
      notification.closeAllMessageCreatedWebNotifications(conversationId);

      if (conversation?.CurrentConversation?.isActiveParticipant) {
        void conversation.getPinnedMessages(conversationId);
      }

      const currConvPbo =
        conversation.loadConversationByIdIfMissingGet(conversationId);
      void currConvPbo.then((resp) => {
        if (resp && resp.data) {
          if (
            resp.data.lastMessageId !== resp.data.readMessageId &&
            conversation.selectConversationById(conversationId).case({
              fulfilled: (resp) => resp.data.isActiveParticipant,
            })
          ) {
            const convMessages =
              message.selectGroupedMessagesForConversation(conversationId);
            void participant
              .updateMyLastReadMessage(
                conversationId,
                convMessages?.NewestMessageId || resp.data.lastMessageId
              )
              .then(() => {
                void ui.setConversationAndTotalUnreadCount(
                  conversationId,
                  0,
                  currConvPbo,
                  0
                );
              });
            window['_currentConvo'] = {
              id: get(resp, 'data.id'),
              grouping: get(resp, 'data.grouping'),
              topic: get(resp, 'data.topic'),
            };
          }
        }
      });
    })();
  }, [
    conversation,
    conversationId,
    loadParticipantsAndMessages,
    message,
    notification,
    participant,
    preferences.showCallMessagesInChat,
    ui,
  ]);

  useEffect(() => {
    void (async () => {
      const prevConversationId = conversation?.previousConversation || '';
      const diffConv = conversationId !== prevConversationId;

      /**
       * This is the primary point in the application where new data is loaded on Conversation change.
       * Ideally in the future, we will move this into the store or something more appropriate.
       */
      if (diffConv) {
        if (conversation?.CurrentConversation?.isActiveParticipant) {
          void conversation.getPinnedMessages(conversationId);
        }

        await loadParticipantsAndMessages(conversationId, {
          Limit: 31,
          SortDirection: 'Descending',
          ShowDeleted: true,
          ShowCallMessagesInChat: preferences.showCallMessagesInChat,
        });
        notification.closeAllMessageCreatedWebNotifications(conversationId);
        message.setCreateMessageMentionListOpen(false);
        message.setEditMessageMentionListOpen(false);
      }
    })();
  }, [
    conversation,
    conversationId,
    loadParticipantsAndMessages,
    message,
    notification,
    participant,
    params.conversationId,
    preferences.showCallMessagesInChat,
    ui,
  ]);

  const grpMsgContainer =
    message.selectGroupedMessagesForConversation(conversationId);

  const lastReadMessageId =
    participant.selectLoggedInUserParticipantLastReadMessageId(conversationId);

  const allParticipants =
    participant.selectParticipantsByConversationId(conversationId);

  if (!isNullOrUndefined(grpMsgContainer)) {
    let loggedInUserOwnsNewestMessage = false;
    if (!isNullOrUndefined(grpMsgContainer.NewestMessage)) {
      loggedInUserOwnsNewestMessage =
        grpMsgContainer.NewestMessage.personId === person.loggedInPersonId;
    }
    return (
      <div
        id="context-content"
        className="flex-column flex-grow-shrink"
        onClick={toggleEmojiPicker}
      >
        {grpMsgContainer?.SortedGroups?.length && conversationId !== '0' ? (
          <ChatLayoutView>
            <Styled.ChatListWrapper
              id="chat-list-wrapper"
              data-hint="This is the message editor emoji picker container"
            >
              <ContextContentItemsList
                auth0={person.auth0}
                ldClient={person.ldClient}
                handleDownloadWithLink={ui.handleDownloadWithLink}
                getFileDownloadLink={message.getFileDownloadLink}
                conversationStore={conversation}
                assignLastScroll={conversation.assignLastScroll}
                callWithPerson={makeCall}
                conversationId={conversationId}
                curConversation={conversation.CurrentConversation}
                deleteMessage={message.deleteMessage}
                editMessage={message.editMessage}
                editMessageDraftHtml={message.editMessageDraftHtml}
                editMessageDraftRaw={message.editMessageDraftRaw}
                getCurrentMessages={
                  message.selectGroupedMessagesForConversation
                }
                getEmojiPickerState={ui.getEmojiPickerState}
                getOrConstructConversationLastScroll={
                  conversation.getOrConstructConversationLastScroll
                }
                getPerson={person.selectPersonById}
                isEditingMessageId={message.isEditingMessageId}
                key={conversationId}
                lastReadMessageId={lastReadMessageId}
                loadLinkPreview={message.loadLinkPreview}
                loadMoreConversationMessages={
                  message.loadMoreConversationMessages
                }
                loggedInPersonId={person.loggedInPersonId}
                loggedInUserActiveConferenceConversation={
                  conversation.LoggedInUserActiveConferenceConversation
                }
                loggedInUserOwnsNewestMessage={loggedInUserOwnsNewestMessage}
                mentionListOpen={message.editMessageMentionListOpen}
                messageMentionFilter={message.editMessageMentionFilter}
                messages={grpMsgContainer}
                messagesWithLinkPreviewHeightCount={
                  grpMsgContainer.MessagesWithLinkPreviewHeightCount
                }
                navigateToConferenceByConfId={
                  ui.navigateVideoConferenceToSession
                }
                newestMessage={grpMsgContainer.NewestMessage}
                newestMessageOwnedByUserId={message.newestMessageOwnedByUserId}
                oldestMessage={grpMsgContainer.OldestMessage}
                resolveConversationLinkPath={resolveConversationLinkPath}
                selectedMentionParticipantId={
                  message.editMessageMentionSelectedParticipantId
                }
                selectFilteredOtherParticipantPersonsInCurrentConversation={
                  participant.selectFilteredOtherParticipantPersonsInCurrentConversation
                } /* Temporarily passing all including self so MD compiler can resolve - RP  */
                selectHasRecentActivityWithin={ui.selectHasRecentActivityWithin}
                isBackfillingToReadMessage={message.selectIsBackfillingToReadMessage(
                  conversationId
                )}
                selectCanLoadOlderMessages={
                  conversation.selectCanLoadOlderMessages
                }
                selectLoadMoreMessagesSkipIds={
                  message.selectLoadMoreMessagesSkipIds
                }
                selectMarkedAsReadInfo={ui.selectMarkedAsReadInfo}
                selectEpochMsSinceMarkedAsRead={
                  ui.selectEpochMsSinceMarkedAsRead
                }
                selectParticipantPersons={participant.selectParticipantPersons}
                selectPersonPresenceStatus={ui.selectPersonPresenceStatus}
                selectUnreadCounts={ui.selectConversationUnreadCounts}
                setEditMessageDraftHtml={message.setEditMessageDraftHtml}
                setEditMessageDraftRaw={message.setEditMessageDraftRaw}
                setIsEditingMessageId={message.setIsEditingMessageId}
                setIsLoadingOlderMessages={
                  conversation.setIsLoadingOlderMessages
                }
                setEmojiPickerState={ui.setEmojiPickerState}
                setMarkedAsReadInfo={ui.setMarkedAsReadMessageId}
                setMentionListOpen={message.setEditMessageMentionListOpen}
                setMessageMentionFilter={message.setEditMessageMentionFilter}
                setSelectedMentionParticipantId={
                  message.setEditMessageMentionSelectedParticipantId
                }
                setConversationAndTotalUnreadCount={
                  ui.setConversationAndTotalUnreadCount
                }
                sortedMessageGroups={grpMsgContainer.SortedGroups}
                totalLinkPreviewHeightSum={
                  grpMsgContainer.TotalLinkPreviewHeightSum
                }
                totalLinkPreviewImageHeightSum={
                  grpMsgContainer.TotalLinkPreviewImageHeightSum
                }
                totalMessageCount={grpMsgContainer.TotalMessageCount}
                totalUnreadCount={grpMsgContainer.TotalUnreadMessageCount}
                updateMyLastReadMessage={participant.updateMyLastReadMessage}
                selectPersonMessageStatus={ui.selectPersonMessageStatus}
                loadPersonByIdGetIfMissingGet={
                  person.loadPersonByIdGetIfMissingGet
                }
                selectParticipantsByConversationId={
                  participant.selectParticipantsByConversationId
                }
                ui={ui}
                allContacts={person.allContacts}
                getExtrContactByPhoneNumber={person.getExtrContactByPhoneNumber}
                listOfPinnedMessages={ui.listOfPinnedMessages}
              />
            </Styled.ChatListWrapper>
          </ChatLayoutView>
        ) : (
          <div>
            {conversation.CurrentConversation?.grouping !== 'Channel' &&
              allParticipants?.case({
                fulfilled: (resp) => {
                  const personsPBO: IPromiseBasedObservable<
                    AxiosResponseT<PersonModel | ContactModelBase>
                  >[] = resp.data.results.map(({ personId, phone }) => {
                    return personId
                      ? person.loadPersonByIdGetIfMissingGet(personId)
                      : contact.loadContactByPhoneNumber(phone);
                  });
                  const personsData = personsPBO
                    .filter(Boolean)
                    .map((personPbo) =>
                      personPbo.state === 'fulfilled'
                        ? personPbo.case({ fulfilled: (resp) => resp.data })
                        : null
                    )
                    .filter((person) => person);
                  const extrContactResp = resp.data.results.find(
                    (resp) => resp.personId === undefined && resp?.phone
                  );
                  const extrContact =
                    extrContactResp &&
                    person.getExtrContactByPhoneNumber(extrContactResp?.phone);
                  return (
                    <PrintMessageHeader
                      hasMessages={!isEmpty(grpMsgContainer.SortedGroups)}
                      persons={personsData}
                      extrContact={extrContact}
                      extrContactPhone={extrContactResp?.phone}
                      loggedInPerson={person.loggedInPersonId}
                      ui={ui}
                    />
                  );
                },
              })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      id="context-content"
      className="flex-column flex-grow-shrink"
      onClick={toggleEmojiPicker}
    />
  );
};

export default inject(
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PERSON,
  STORE_PUSHER,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_UI
)(withRouter(observer(ContextContent)));
