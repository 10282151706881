import type { ICloudProps } from './types';
import closeBtn from 'Assets/images/close-btn.svg';
import iCloudIcon from 'Assets/images/icloud-icon.svg';
import externalLinkIcon from 'Assets/images/icon-external-link.svg';
import { CALENDAR_IDS, CALENDAR_SCOPE, SHOW_CALENDAR } from 'Constants/env';
import { STORE_NOTIFICATION, STORE_PERSON } from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import { Scope } from 'Models/Calendar';
import * as React from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import ProviderButton from '../ProviderButton';

interface ICloudState {
  isModalOpen: boolean;
  email: { value: string; error?: string };
  password: { value: string; error?: string };
  showCalendar: boolean;
}
class ICloudAddressBook extends React.Component<ICloudProps, ICloudState> {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      email: {
        value: '',
        error: undefined,
      },
      password: {
        value: '',
        error: undefined,
      },
      showCalendar: false,
    };
  }

  componentDidMount(): void {
    const showCalendar =
      SHOW_CALENDAR ||
      CALENDAR_IDS?.includes(this.props.person.loggedInPersonId);

    this.setState({ ...this.state, showCalendar });
  }

  componentDidUpdate() {
    const addressContainer = document.getElementById('addressBook-container');
    const calendarContainer = document.getElementById('calendar-container');
    const container = addressContainer || calendarContainer;
    this.state.isModalOpen && container?.scrollTo(0, 0);
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [e.target.name]: { value: e.target.value },
    });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setInputError = (key: string, error?: string) => {
    this.setState({
      ...this.state,
      [key]: {
        ...this.state[key],
        error: error,
      },
    });
  };

  handleSubmit = async () => {
    const { email, password } = this.state;
    const scopes = ['contacts'] as Scope[];
    this.state.showCalendar && scopes.push(CALENDAR_SCOPE);
    if (email.value.length === 0 || !this.validateEmail(email.value)) {
      this.setInputError('email', 'Email is empty or invalid!');
      return;
    }
    if (password.value.length === 0) {
      this.setInputError('password', 'Password is empty!');
      return;
    }
    const resp = await this.props.person.sendCodeOfProvider(
      '',
      'icloud',
      scopes,
      email.value,
      password.value
    );
    if (resp) {
      this.setState({
        isModalOpen: false,
        email: {
          value: '',
          error: undefined,
        },
        password: {
          value: '',
          error: undefined,
        },
      });
    } else {
      this.setInputError('email', 'Please check Email !');
      this.setInputError('password', 'Please check Password!');
    }
  };

  handleModalToggle = () => {
    this.setState({
      ...this.state,
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      email: {
        value: '',
      },
      password: {
        value: '',
      },
    });
  };

  render() {
    return (
      <>
        <ProviderButton
          text="Sign in with iCloud"
          logo={iCloudIcon}
          onClick={this.handleModalToggle}
        />
        {this.state.isModalOpen && (
          <div className="icould-modal">
            <div className="source-list">
              <h2 className="settings-title">
                Synchronize contacts (Add a new source from iCloud)
              </h2>
              <p className="settings-description">
                You first have to generate an app specific password to integrate
                your iCloud account with <br />
                Broadvoice Communicator
              </p>

              <a
                href="https://support.apple.com/HT204397"
                target="_blank"
                className="ui button icloud-generate-password flex-grow-shrink"
                rel="noreferrer"
              >
                GENERATE PASSWORD <img src={externalLinkIcon} />
              </a>

              <Grid.Row className="ui grid margin-top-0 margin-bottom-0">
                <Grid.Column width={6}>
                  <Form.Field className="input-self input-self--secondary">
                    <Form.Input
                      fluid
                      id="password"
                      name="password"
                      label="Enter your password generated on iCloud"
                      placeholder="Generated Password"
                      value={this.state.password.value || ''}
                      onChange={this.handleInputChange}
                    />
                    {this.state.password.error && (
                      <span className="error-message">
                        {this.state.password.error}
                      </span>
                    )}
                  </Form.Field>
                  <Form.Field className="input-self input-self--secondary">
                    <Form.Input
                      fluid
                      id="email"
                      name="email"
                      label="Enter your iCloud email"
                      placeholder="iCloud Email"
                      value={this.state.email.value || ''}
                      onChange={this.handleInputChange}
                    />
                    {this.state.email.error && (
                      <span className="error-message">
                        {this.state.email.error}
                      </span>
                    )}
                  </Form.Field>
                  <div className="source-list--buttons flex-row">
                    <Button content="CONTINUE" onClick={this.handleSubmit} />
                    <Button content="CANCEL" onClick={this.handleClose} />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </div>
            <img
              src={closeBtn}
              className="icould-modal--close"
              onClick={this.handleClose}
            />
          </div>
        )}
      </>
    );
  }
}
export default inject(
  STORE_PERSON,
  STORE_NOTIFICATION
)(observer(ICloudAddressBook));
