import { Loader as SemanticLoader } from 'semantic-ui-react';
import styled from 'styled-components';

const SourceBox = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.base};
  padding: 0px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.border};
  }

  &:active {
    background: ${({ theme }) => theme.colors.mediumGray};
  }
`;

const SourceBoxLabel = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
`;

const Loader = styled(SemanticLoader)`
  display: block;
  position: static !important;
  margin: 0 auto !important;
  display: block !important;
`;

export const Styled = { SourceBox, SourceBoxLabel, Loader };
