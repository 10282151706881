import styled from 'styled-components';

const ImageButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;

  & img {
    width: 17px;
    height: auto;
    max-height: 17px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.linkHover};
  }
`;

const LabeledButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 22px;
  color: rgb(51, 62, 72, 0.5);
`;

const ActionsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 27px;
  margin: 0 auto;
  margin-top: 30px;
`;

const EmailAttribute = styled.div`
  text-align: center;
  margin-top: 5px;
`;

export const Styled = {
  ImageButton,
  LabeledButton,
  ActionsGroup,
  EmailAttribute,
};
