import type { ProviderButtonProps } from './types';
import React from 'react';
import { Styled } from './index.styles';

const ProviderButton = ({
  text,
  logo,
  onClick,
  isLoading,
  testId,
}: ProviderButtonProps): JSX.Element => {
  return (
    <div>
      {isLoading && <Styled.Loader />}

      <Styled.SourceBox onClick={onClick} data-testid={testId}>
        <img src={logo} />

        <Styled.SourceBoxLabel>{text}</Styled.SourceBoxLabel>
      </Styled.SourceBox>
    </div>
  );
};

export default ProviderButton;
