import { isEmpty } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import { Contact } from 'Models/Contacts';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStoreProps } from 'Stores/RootStore.types';
import { formatNumberWithNationalCode } from 'Utils/phoneUtil';
import EntityAttribute from './EntityAttribute';
import { Styled } from './index.styles';
import { EntityProfileComponentProps } from './interfaces';

const EntityProfileHeader: React.FC<EntityProfileComponentProps> = ({
  contact,
  extrContacts,
  person,
}) => {
  const navigate = useNavigate();

  const {
    person: { setEditContact, setIsAddingContact, getExtrContactByPhoneNumber },
    ui: uiStore,
  } = React.useContext(MobXProviderContext) as RootStoreProps;

  const mapName = useCallback(
    (returnName?: boolean) => {
      const checkIfExtrContact =
        contact?.phoneNumber &&
        getExtrContactByPhoneNumber(contact?.phoneNumber);
      if (extrContacts || checkIfExtrContact) {
        return (
          checkIfExtrContact?.DisplayName() || extrContacts[0]?.DisplayName()
        );
      } else if (person) {
        return person.DisplayName;
      } else if (!person && !extrContacts && contact) {
        return returnName && contact.DisplayName;
      }
      return '';
    },
    [contact, extrContacts, getExtrContactByPhoneNumber, person]
  );

  const addExtrContact = (e) => {
    e.preventDefault();
    const newExtrContact = new Contact({
      id: null,
      providerContactId: null,
      providerAccountId: null,
      phoneNumbers: [{ id: null, type: '', number: contact.phoneNumber }],
      emails: [],
      firstName: null,
      lastName: null,
      middleName: null,
      organization: null,
      organizationRole: null,
      createdAt: null,
      updatedAt: null,
      platformUserId: null,
      platformAccountId: null,
      hasProfilePicture: null,
      notes: null,
      isShared: null,
      pictureKey: null,
    });
    setIsAddingContact(true);
    setEditContact(newExtrContact);
    navigate('/addressBook/contact', { state: { from: '/chat' } });
  };

  const mapProperImage = useCallback(() => {
    if (person) {
      return person?.profilePictureUrl || '';
    } else if (extrContacts) {
      const extrContact = extrContacts[0];
      return extrContact?.pictureUrl || '';
    }
    return '';
  }, [person, extrContacts]);

  const name = mapName();
  const image = mapProperImage();
  const contactName = contact && formatNumberWithNationalCode(mapName(true));
  const userStatus = person && uiStore.selectPersonMessageStatus(person.id);
  const presence = person && uiStore.selectPersonPresenceStatus(person?.id);

  return (
    <Styled.EntityProfileHeader id="entity-profile-header">
      <Styled.UserAvatar
        image={image}
        name={name}
        selectUnreadCounts={uiStore.selectConversationUnreadCounts}
        presence={presence}
        key={`entity-profile-avatar-${person?.id}-${presence?.state}`}
      />
      {!isEmpty(person) && (
        <EntityAttribute name={name} userStatus={userStatus} />
      )}
      {!isEmpty(contact) && !extrContacts && !person && (
        <EntityAttribute
          name={contactName ? contactName : mapName(true)}
          userStatus={userStatus}
        >
          <Styled.AddContactLink onClick={addExtrContact} role="link">
            Add Contact
          </Styled.AddContactLink>
        </EntityAttribute>
      )}
      {!isEmpty(extrContacts) && !person && name && (
        <EntityAttribute name={name} userStatus={userStatus} />
      )}
    </Styled.EntityProfileHeader>
  );
};

export default observer(EntityProfileHeader);
