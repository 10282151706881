import type { AxiosResponse } from 'axios';
import {
  API_ENDPOINTS,
  EMPTY_TIMEUUID,
  NODE_ENV_PRODUCTION,
} from 'Constants/env';
import { ParticipantSummary } from 'Interfaces/apiDtos';
import {
  AxiosResponseT,
  ResultsCollection,
  ResultsCollectionObservable,
} from 'Interfaces/axiosResponse';
import { IUpdateMyLastReadMessageResult } from 'Interfaces/components';
import { IParticipantPerson } from 'Interfaces/participantPerson';
import { get, isEmpty, sortBy, uniqBy } from 'lodash';
import {
  action,
  computed,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import { fromPromise, createTransformer } from 'mobx-utils';
import type { IPromiseBasedObservable } from 'mobx-utils';
import { ContactModel } from 'Models/ContactModel';
import { RootStore } from 'Stores/RootStore';
import { isNullOrUndefined } from 'util';
import { getCorrectedMsFromUnixEpoch } from 'Utils/timeUUIDParser';
import API from '../api';
import { ConversationModel, ParticipantModel, PersonModel } from '../models';
import { bugsnagClient } from '../utils/logUtils';
import { BaseStore } from './BaseStore';

export class ParticipantStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @observable public participantByConvMap = observable.map<
    string,
    IPromiseBasedObservable<
      AxiosResponseT<ResultsCollectionObservable<ParticipantModel>>
    >
  >();

  @observable public conversationMessagesSMS = observable.map<
    string,
    boolean
  >();

  /** Last `readMessageId` of the current logged-in user, per `Conversation` */
  public lastReadByConvMap = observable.map<string>();

  @observable public createParticipantPostStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<ParticipantModel>>
  > = null;

  @observable public removeParticipantsDeleteStatus: IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<ParticipantModel>>
  > = null;

  @observable
  public updateLastReadMessagePutStatus: IPromiseBasedObservable<AxiosResponse> =
    null;

  /** Track the `Person` ids to add to a new `Conversation` (with `id` 0) */
  @observable newConversationPersons = observable.array<number>();

  @action
  clearAllData = () => {
    this.participantByConvMap.clear();
    this.lastReadByConvMap.clear();
    this.newConversationPersons.clear();
    this.conversationMessagesSMS.clear();
    this.createParticipantPostStatus = null;
    this.removeParticipantsDeleteStatus = null;
    this.updateLastReadMessagePutStatus = null;
  };

  @action
  addPersonToNewConversation = (personId: number) => {
    this.newConversationPersons.push(personId);
  };

  @action
  resetNewConversationPersons = () => {
    this.newConversationPersons = observable.array();
  };

  @computed
  get NewConversationParticipantPartials(): Pick<
    ParticipantModel,
    'personId'
  >[] {
    return this.newConversationPersons.map((ncp) => ({ personId: ncp }));
  }

  /** Select a `IPromiseBasedObservable` representing the list of `Participants` in the given `Conversation.id` */
  selectParticipantsByConversationId = createTransformer(
    (conversationId: string) => {
      return this.participantByConvMap.has(conversationId)
        ? this.participantByConvMap.get(conversationId)
        : null;
    }
  );

  /** Select the `ParticipantModel` for the logged in user.
   *
   * Returns `null` if `this.participantByConvMap(conversationId)` is missing or its state is not 'fulfilled'
   */
  selectLoggedInUserParticipant = createTransformer(
    (conversationId: string) => {
      const participantsPbo =
        this.selectParticipantsByConversationId(conversationId);
      if (participantsPbo !== null && participantsPbo.state === 'fulfilled') {
        return participantsPbo.value.data.results.find(
          (p) => p.personId === this.rootStore.personStore.loggedInPersonId
        );
      }
      return null;
    }
  );

  selectLoggedInUserParticipantLastReadMessageId = createTransformer(
    (conversationId: string) => {
      let lastReadMsgId = null;
      if (this.lastReadByConvMap.has(conversationId)) {
        if (this.lastReadByConvMap.get(conversationId) !== EMPTY_TIMEUUID) {
          lastReadMsgId = this.lastReadByConvMap.get(conversationId);
        } else {
          bugsnagClient.notify(
            `selectLoggedInUserParticipantLastReadMessageId retrieved invalid empty lastReadMsgId for Conversation ${conversationId}`,
            (event) => {
              event.severity = 'warning';
              event.context = 'ParticipantStore';
              event.addMetadata('custom', {
                function: 'selectLoggedInUserParticipantLastReadMessageId',
              });
            }
          );
        }
      } else {
        bugsnagClient.notify(
          `selectLoggedInUserParticipantLastReadMessageId no entry in lastReadByConvMap for Conversation ${conversationId}`,
          (event) => {
            event.severity = 'warning';
            event.context = 'ParticipantStore';
            event.addMetadata('custom', {
              function: 'selectLoggedInUserParticipantLastReadMessageId',
            });
          }
        );
      }

      // Still null, if possible, return the 2nd most recent Message Id, so the
      if (lastReadMsgId === null) {
        const gmfc =
          this.rootStore.messageStore.selectGroupedMessagesForConversation(
            conversationId
          );
        if (!isNullOrUndefined(gmfc)) {
          const lastReadMsgIdObservable = (lastReadMsgId =
            this.rootStore.conversationStore.selectConversationById(
              conversationId
            ));

          if (lastReadMsgIdObservable) {
            lastReadMsgId = lastReadMsgIdObservable.case({
              fulfilled: (conversation) => {
                return conversation?.data?.readMessageId;
              },
              pending: () => null,
              rejected: (err) => {
                console.error(
                  `selectLoggedInUserParticipantLastReadMessageId selectConversationById was rejected`,
                  err
                );
                return null;
              },
            });
          }
        } else {
          bugsnagClient.notify(
            `selectLoggedInUserParticipantLastReadMessageId no entry in groupedMessagesByConversationMap for Conversation ${conversationId}`,
            (event) => {
              event.severity = 'warning';
              event.context = 'ParticipantStore';
              event.addMetadata('custom', {
                function: 'selectLoggedInUserParticipantLastReadMessageId',
              });
            }
          );
        }
      }

      // Still null, attempt to use `Participant` load PBO to populate it
      if (
        lastReadMsgId === null &&
        this.participantByConvMap.has(conversationId)
      ) {
        lastReadMsgId = this.participantByConvMap.get(conversationId).case({
          fulfilled: (lp) => {
            if (!isEmpty(lp)) {
              const loggedInUserPtc = lp.data.results.find(
                (p) =>
                  p.personId === this.rootStore.personStore.loggedInPersonId
              );
              if (!isNullOrUndefined(loggedInUserPtc)) {
                return loggedInUserPtc.readMessageId;
              }
            }
            return null;
          },
          pending: () => null,
          rejected: (err) => {
            console.error(
              `selectLoggedInUserParticipantLastReadMessageId loadMessagesPbo was rejected`,
              err
            );
            return null;
          },
        });
      }

      // Still null, attempt to use `Message` load PBO to populate it (because previous attempt to `selectGroupedMessagesForConversation` was null)
      if (
        lastReadMsgId === null &&
        this.rootStore.messageStore.messageByConvStatusMap.has(conversationId)
      ) {
        const loadMessagesPbo =
          this.rootStore.messageStore.messageByConvStatusMap.get(
            conversationId
          );
        lastReadMsgId = loadMessagesPbo.case({
          fulfilled: (_) => {
            const gmc =
              this.rootStore.messageStore.selectGroupedMessagesForConversation(
                conversationId
              );
            if (!isNullOrUndefined(gmc)) {
              if (gmc.NewestFiveMessageIds.length > 1) {
                return gmc.NewestFiveMessageIds[1];
              }
            }
            return null;
          },
          pending: () => null,
          rejected: (err) => {
            console.error(
              `selectLoggedInUserParticipantLastReadMessageId loadMessagesPbo was rejected`,
              err
            );
            return null;
          },
        });
      }

      // If everything else failed, this will return `EMPTY_MESSAGE_ID`
      return lastReadMsgId || EMPTY_TIMEUUID;
    }
  );

  /**
   * Select a list of `Participants` in the given `Conversation.id`  who are NOT the logged-in `Person`
   *
   * **NOTE:** Will only return `Participant`s with a valid `personId`
   *
   * Returns `null` if `this.participantByConvMap(conversationId)` is missing or its state is not 'fulfilled'
   */
  selectOtherParticipants = createTransformer((conversationId: string) => {
    const participantsPbo =
      this.selectParticipantsByConversationId(conversationId);
    if (participantsPbo !== null && participantsPbo.state === 'fulfilled') {
      const results = uniqBy(
        participantsPbo.value.data.results.filter(
          (p) =>
            (p.personId &&
              p.personId !== this.rootStore.personStore.loggedInPersonId) ||
            !isEmpty(p.phone)
        ),
        (p) => p.id
      );
      return results;
    }
    return null;
  });

  /**
   * Returns the array from `selectParticipants` linked to the associated `PersonModel`s,
   * wrapped in `IParticipantPerson`.
   *
   * **NOTE:** Will only return `Participant`s with a valid `personId`
   *
   * Returns `null` if `this.selectOtherParticipants(conversationId)` returns null
   */
  selectParticipantPersons = createTransformer((conversationId: string) => {
    const participantsPbo =
      this.selectParticipantsByConversationId(conversationId);
    const participantPersons: IParticipantPerson[] = [];
    if (participantsPbo !== null && participantsPbo.state === 'fulfilled') {
      participantsPbo.value.data.results.forEach((p) => {
        if (p.personId) {
          const prsResp = this.rootStore.personStore.selectPersonById(
            p.personId
          );
          if (prsResp !== null && prsResp.state === 'fulfilled') {
            participantPersons.push({
              participant: p,
              person: prsResp.value.data,
              conversationId,
            });
          }
        }
      });
      return sortBy(
        uniqBy(participantPersons, (pp) => pp.person.id),
        'person.firstName',
        'person.lastName'
      );
    }
    return null;
  });

  /**
   * Returns the array from `selectOtherParticipants` linked to the associated `PersonModel`s,
   * wrapped in `IParticipantPerson`.
   *
   * **NOTE:** Will only return `Participant`s with a valid `personId`
   *
   * Returns `null` if `this.selectOtherParticipants(conversationId)` returns null
   */
  selectOtherParticipantPersons = createTransformer(
    (conversationId: string) => {
      const participantPersons = this.selectParticipantPersons(conversationId);
      if (participantPersons === null) {
        return null;
      }
      return uniqBy(
        participantPersons.filter(
          (pp) =>
            pp.person &&
            pp.person.id !== this.rootStore.personStore.loggedInPersonId
        ),
        (pp) => pp.person.id
      );
    }
  );

  selectPersonsNotInConversation = createTransformer(
    (conversationId: string) => {
      const nonParticipants =
        observable.array<
          IPromiseBasedObservable<AxiosResponseT<PersonModel>>
        >();
      // Existing Conversation
      if (conversationId !== '0') {
        const participantsPbo =
          this.selectParticipantsByConversationId(conversationId);
        if (participantsPbo && participantsPbo.state === 'fulfilled') {
          for (const personPbo of Array.from(
            this.rootStore.personStore.personsById.values()
          )) {
            if (personPbo.state === 'fulfilled') {
              const prs = personPbo.value.data;
              if (
                !participantsPbo.value.data.results.some(
                  (pts) => pts.personId === prs.id
                )
              ) {
                nonParticipants.push(personPbo);
              }
            }
          }
        }
      } else {
        for (const personPbo of Array.from(
          this.rootStore.personStore.personsById.values()
        )) {
          if (personPbo.state === 'fulfilled') {
            const person = personPbo.value;
            if (!this.newConversationPersons.includes(person.data.id)) {
              nonParticipants.push(personPbo);
            }
          }
        }
      }

      return nonParticipants;
    }
  );

  /**
   * Select **other** `IParticipantPerson`s in the current `Conversation` (as determined by `ConversationStore.CurrentConversation`),
   * filtered by the provided `filterVal`, which is lower-cased, then compared against lower-cased
   * `person.firstName` and `person.lastName`, to determine if either starts with the filter value.
   * Returns matching `IParticipantPerson`s, if any.
   *
   * - If `filterVal` is an empty string, returns the result of `selectOtherParticipantPersons`
   * - If there is no current `Conversation`, returns `null`.
   * - If `selectParticipantPersons` returns `null`, this method returns `null`
   * - If there are no matches, returns an empty array
   */
  selectFilteredOtherParticipantPersonsInCurrentConversation =
    createTransformer((filterVal: string) => {
      if (this.rootStore.conversationStore.CurrentConversation === null) {
        return null;
      }
      const otherPtcPersons = this.selectOtherParticipantPersons(
        this.rootStore.conversationStore.CurrentConversation.id
      );
      if (otherPtcPersons === null) {
        return null;
      }
      if (isEmpty(filterVal)) {
        return otherPtcPersons;
      }

      const fvlc = filterVal.toLowerCase();
      return otherPtcPersons.filter((opp) => {
        const fn = get(opp, 'person.firstName', '').toLowerCase();
        const ln = get(opp, 'person.lastName', '').toLowerCase();
        return fn.startsWith(fvlc) || ln.startsWith(fvlc);
      });
    });

  getParticipantsMockedResponse = (
    conversations: ConversationModel
  ): Promise<any> => {
    const axiosResponse: AxiosResponse<
      ResultsCollectionObservable<Partial<ParticipantModel>>
    > = {
      data: {
        results: observable.array(
          conversations.participants.map(({ personId, phone }) =>
            personId ? { id: `${personId}_`, personId } : { phone }
          )
        ),
      },
      status: 200,
      statusText: 'OK',
      config: {},
      headers: {},
    };
    return new Promise((resolve) => resolve(axiosResponse));
  };

  /**
      Check if participants are already loaded.
     */
  checkAreFetchedParticipants = async (conversationId: string) => {
    const isInParticipantsList = await this.participantByConvMap.get(
      conversationId
    );
    if (isInParticipantsList) {
      return isInParticipantsList;
    }
    const isConversationLoaded =
      await this.rootStore.conversationStore.conversationByIdMap.get(
        conversationId
      );
    if (
      isConversationLoaded &&
      isConversationLoaded.data.participants.length > 0
    ) {
      const participants = fromPromise(
        this.getParticipantsMockedResponse(isConversationLoaded.data)
      );
      // setLoadParticipantPbo
      runInAction(() =>
        this.participantByConvMap.set(conversationId, participants)
      );
      return participants;
    }
    return null;
  };

  /**
   * fetches and loads `Participant`s for a `Conversation` into `this.participantByConvMap`.
   */
  @action
  fetchAndLoadConversationParticipants = (conversationId: string) => {
    const participantsFetchedPbo =
      this.checkAreFetchedParticipants(conversationId);

    return fromPromise(
      this.rootStore.personStore.waitUntilLoggedIn().then(() => {
        return participantsFetchedPbo.then((resp) => {
          const loadParticipantsPbo = fromPromise(
            API.get(
              API_ENDPOINTS.ConversationParticipantsGet(conversationId, {
                Limit: 100,
              })
            )
          );

          // setLoadParticipantPBbo
          runInAction(() =>
            this.participantByConvMap.set(conversationId, loadParticipantsPbo)
          );
          loadParticipantsPbo.then(
            (resp) =>
              this.loadConversationParticipantsSuccess(conversationId, resp),
            this.loadConversationParticipantsError
          );
          return loadParticipantsPbo;
        });
      })
    );
  };

  /**
   * Load `Participant`s for a `Conversation` into `this.participantByConvMap`.
   */
  @action
  loadConversationParticipants = (conversationId: string) => {
    const participantsFetchedPbo =
      this.checkAreFetchedParticipants(conversationId);
    return fromPromise(
      this.rootStore.personStore.waitUntilLoggedIn().then(() => {
        return participantsFetchedPbo.then((resp) => {
          if (resp) {
            return resp;
          }
          if (!this.participantByConvMap.has(conversationId)) {
            const loadParticipantsPbo = fromPromise(
              API.get(
                API_ENDPOINTS.ConversationParticipantsGet(conversationId, {
                  Limit: 100,
                })
              )
            );
            // setLoadParticipantPBbo
            runInAction(() =>
              this.participantByConvMap.set(conversationId, loadParticipantsPbo)
            );
            loadParticipantsPbo.then(
              (resp) =>
                this.loadConversationParticipantsSuccess(conversationId, resp),
              this.loadConversationParticipantsError
            );
            return loadParticipantsPbo;
          } else {
            return this.participantByConvMap.get(conversationId);
          }
        });
      })
    );
  };

  /** Load `Participant`s for a `Conversation` into `this.participantByConvMap` only if the key (`conversationId`) is missing */
  @action
  loadConversationParticipantsIfMissing = (conversationId: string) => {
    if (!this.participantByConvMap.has(conversationId)) {
      return this.loadConversationParticipants(conversationId);
    }
    return this.participantByConvMap.get(conversationId);
  };

  /**
   * Maps each `Participant` into `this.participantByConvMap`.
   */
  @action
  loadConversationParticipantsSuccess(
    conversationId: string,
    resp: AxiosResponseT<ResultsCollection<ParticipantModel>>,
    convertFromDto = true
  ) {
    let ptcModels: IPromiseBasedObservable<
      AxiosResponseT<ResultsCollectionObservable<ParticipantModel>>
    >;
    const respResults: ParticipantModel[] = get(resp, 'data.results', []);

    if (respResults.length === 0) {
      bugsnagClient.notify(
        'loadConversationParticipantsSuccess respResults.length === 0',
        (event) => {
          event.severity = 'warning';
          event.context = 'ParticipantStore';
          event.addMetadata('custom', {
            function: 'loadConversationParticipantsSuccess',
          });
        }
      );
    }
    if (convertFromDto) {
      const nextId = get(resp, 'data.nextId', '');
      ptcModels = fromPromise.resolve({
        ...resp,
        data: {
          nextId: nextId,
          results: observable.array(
            respResults.map(ParticipantModel.FromResponseDto)
          ),
        },
      });
      this.participantByConvMap.set(conversationId, ptcModels);
    } else {
      ptcModels = this.participantByConvMap.get(conversationId);
    }

    this.conversationMessagesSMS.set(
      conversationId,
      respResults.some((p) => !isEmpty(p.phone))
    );
    if (ptcModels.state === 'fulfilled') {
      // TODO: Return this Promise? (RP 2019-09-26)
      return ptcModels.then((p) => {
        return p.data.results.map((participant) => {
          if (
            participant.personId !== undefined &&
            participant.personId !== null
          ) {
            // If the `Participant` is the logged-in user, records their `readMessageId`, if present, for `conversationId`
            if (
              participant.personId ===
                this.rootStore.personStore.loggedInPersonId &&
              !isEmpty(participant.readMessageId) &&
              !this.lastReadByConvMap.has(conversationId)
            ) {
              this.lastReadByConvMap.set(
                conversationId,
                participant.readMessageId
              );
            }
            return this.rootStore.personStore
              .loadPersonByIdGetIfMissingGet(participant.personId)
              .then(
                (l) => l,
                (err) => err
              ) as Promise<AxiosResponseT<PersonModel>>;
          } else {
            return this.rootStore.contactStore
              .loadContactByPhoneNumber(participant.phone)
              .then(
                (l) => l,
                (err) => err
              ) as Promise<AxiosResponseT<ContactModel>>;
          }
        });
      });
    } else {
      const errMsg = `loadConversationParticipantsSuccess: Fatal error, ptcModels was in a non-fulfilled state: ${ptcModels.state}`;
      console.error(errMsg);
      return Promise.reject(errMsg);
    }
  }

  loadConversationParticipantsError = (reason: any) =>
    this.rootStore.notificationStore.addAxiosErrorNotification(
      reason,
      'Error loading Conversation Participants'
    );

  @action
  reloadParticipantsForAllConversations = async () => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    const convIds = this.rootStore.conversationStore.conversationByIdMap.keys();
    const reloadPromises: IPromiseBasedObservable<AxiosResponse<any> | void>[] =
      [];
    for (const c of Array.from(convIds)) {
      reloadPromises.push(this.loadConversationParticipants(c));
    }
    return Promise.all(reloadPromises);
  };

  /**
   * Add a `Participant` to a `Conversation`. Either `personId` or `phone` must be provided, but not both (pass `null` to `personId` if you're providing a `phone`)
   *
   * @param conversationId
   * @param [personId] The `id` of the `Person` being added to the conversation. Mutually exclusive with `phone`.
   * @param [phone] A phone number being added to the conversation. Mutually exclusive with `personId`.
   * @returns {IPromiseBasedObservable<AxiosResponseT<ResultsCollection<ParticipantModel>>>}
   */
  @action
  createConversationParticipant = (
    conversationId: string,
    personId?: number[]
  ): IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<ParticipantModel>>
  > => {
    return fromPromise(
      this.rootStore.personStore.waitUntilLoggedIn().then(() => {
        if (!personId) {
          throw Error(
            'Error: personId is required to create a participant. Please provide a valid personId.'
          );
        }

        this.createParticipantPostStatus = fromPromise(
          API.post(API_ENDPOINTS.ConversationParticipants(conversationId), {
            newParticipants: personId.map((p) => ({ personId: p })),
          })
        );
        this.createParticipantPostStatus.then(
          (resp) => {
            this.rootStore.notificationStore.addNotification(
              'User Added Successfully',
              null,
              'success'
            );
          },
          (reason) =>
            this.rootStore.notificationStore.addAxiosErrorNotification(
              reason,
              'Error adding Conversation Participant'
            )
        );
        return this.createParticipantPostStatus;
      })
    );
  };

  /**
   * Remove one or more `Participant`s from the `Conversation`
   *
   * @param conversationId {string} Remove `Participant`s from this `Conversation`
   * @param participantIds {...string[]} One or more `Participant` `id`s to remove from the `Conversation`
   * @returns {IPromiseBasedObservable<AxiosResponseT<ResultsCollection<ParticipantModel>>>}
   */
  @action
  removeParticipantsDelete = (
    conversationId: string,
    participantIds: string[]
  ): IPromiseBasedObservable<
    AxiosResponseT<ResultsCollection<ParticipantModel>>
  > => {
    return fromPromise(
      this.rootStore.personStore.waitUntilLoggedIn().then(() => {
        this.removeParticipantsDeleteStatus = fromPromise(
          API.delete(API_ENDPOINTS.ConversationParticipants(conversationId), {
            data: {
              participantIds,
            },
          })
        );

        // TODO: Removing from the list could be done before the Promise is resolved, possibly rollback on failure (RP 11/17/2017)
        this.removeParticipantsDeleteStatus.then(
          (resp) => {
            const existingParticipantsPbo =
              this.participantByConvMap.get(conversationId);
            if (
              existingParticipantsPbo !== undefined &&
              existingParticipantsPbo.state === 'fulfilled'
            ) {
              // filter removed Participants from results
              runInAction(() => {
                participantIds.forEach((participantId) => {
                  const removeParticipant =
                    existingParticipantsPbo.value.data.results.find(
                      ({ id }) => id === participantId
                    );

                  if (removeParticipant) {
                    existingParticipantsPbo.value.data.results.remove(
                      removeParticipant
                    );
                  }
                });
              });
            }
            this.rootStore.notificationStore.addNotification(
              'User Removed',
              null,
              'success'
            );
          },
          (reason) =>
            this.rootStore.notificationStore.addAxiosErrorNotification(
              reason,
              'Error removing Conversation Participant'
            )
        );

        return this.removeParticipantsDeleteStatus;
      })
    );
  };

  /**
   * Send a `Message` Id as the last read message for a `Conversation`.
   *
   * **You should call this after `UiStore.setConversationAndTotalUnreadCount` is completed (within the `then` handler)**
   *
   * **NOTE:** This does not affect the actual _count_ of Unread `Message`s. Use `UiStore.setConversationAndTotalUnreadCount` for the count.
   */
  @action
  updateMyLastReadMessage = async (
    conversationId: string,
    nextReadMessageId: string
  ): Promise<IUpdateMyLastReadMessageResult> => {
    await this.rootStore.personStore.waitUntilLoggedIn();
    return this.sendUpdateMyLastReadMessage(conversationId, nextReadMessageId);
  };

  @action
  sendUpdateMyLastReadMessage(
    conversationId: string,
    nextReadMessageId: string
  ): PromiseLike<IUpdateMyLastReadMessageResult> {
    const currentReadMsgId =
      this.selectLoggedInUserParticipantLastReadMessageId(conversationId);
    const currReadMoment = getCorrectedMsFromUnixEpoch(currentReadMsgId);
    const nextReadMoment = getCorrectedMsFromUnixEpoch(nextReadMessageId);
    // Only mark if different and newer
    if (
      isEmpty(currentReadMsgId) ||
      currentReadMsgId === EMPTY_TIMEUUID ||
      (currentReadMsgId !== nextReadMessageId &&
        nextReadMoment > currReadMoment)
    ) {
      const updateReadMsgPbo = fromPromise(
        API.put(API_ENDPOINTS.ConversationParticipantsMe(conversationId), {
          readMessageId: nextReadMessageId,
        })
      );
      this.updateLastReadMessagePutStatus = updateReadMsgPbo;
      return updateReadMsgPbo.then(
        (resp) =>
          this.updateMyLastReadMessageSuccess(
            conversationId,
            nextReadMessageId
          ),
        (reason) =>
          this.rootStore.notificationStore.addAxiosErrorNotification(
            reason,
            'Error updating last read message'
          )
      ) as Promise<IUpdateMyLastReadMessageResult>;
    }
  }

  @action
  updateMyLastReadMessageSuccess = (
    conversationId: string,
    nextReadMessageId: string
  ): IUpdateMyLastReadMessageResult => {
    const currentLastReadMsgId =
      this.selectLoggedInUserParticipantLastReadMessageId(conversationId);
    const currLrmMoment = getCorrectedMsFromUnixEpoch(currentLastReadMsgId);
    const nextLrmMoment = getCorrectedMsFromUnixEpoch(nextReadMessageId);
    if (nextLrmMoment > currLrmMoment) {
      this.lastReadByConvMap.set(conversationId, nextReadMessageId);
    } else {
      bugsnagClient.notify(
        `updateMyLastReadMessageSuccess is not updating from current ${currentLastReadMsgId} (created ${currLrmMoment}) to next ${nextReadMessageId} (created ${nextLrmMoment})`,
        (event) => {
          event.severity = 'warning';
          event.context = 'ParticipantStore';
          event.addMetadata('custom', {
            function: 'updateMyLastReadMessageSuccess',
          });
        }
      );
    }
    return { conversationId, readMessageId: nextReadMessageId };
  };

  /**
   * Remove the `Participant`s and any other data related to a `Conversation` from the store.
   *
   * Intended to handle `ConversationLeave` push notifications, should be called by `ConversationStore.removeLocalConversationData`
   */
  @action
  removeLocalConversationData = (conversationId: string) => {
    this.participantByConvMap.delete(conversationId);
  };

  @action
  insertLocalPushParticipants = (
    conversationId: string,
    participantsToAdd: ParticipantSummary[]
  ) => {
    const existingParticipantsPbo =
      this.participantByConvMap.get(conversationId);

    if (existingParticipantsPbo?.state === 'fulfilled') {
      runInAction(() => {
        participantsToAdd.forEach((participant) => {
          const isParticipantAlreadyAdded =
            existingParticipantsPbo.value.data.results.find(
              ({ id }) => id === participant.id
            );

          if (!isParticipantAlreadyAdded) {
            existingParticipantsPbo.value.data.results.push(
              ParticipantModel.FromResponseDto(participant)
            );
          }
        });
      });
    } else {
      if (!NODE_ENV_PRODUCTION) {
        console.debug(
          `insertNewPushParticipant did not find existing list of Participants for Conversation ${conversationId}, loading them now...`
        );
      }
      this.loadConversationParticipants(conversationId);
    }
  };

  @action
  removeLocalParticipants = (
    conversationId: string,
    participantsToRemove: ParticipantSummary[]
  ) => {
    const existingParticipantsPbo =
      this.participantByConvMap.get(conversationId);

    if (existingParticipantsPbo?.state === 'fulfilled') {
      runInAction(() => {
        participantsToRemove.forEach((participant) => {
          const removeParticipants =
            existingParticipantsPbo.value.data.results.filter(
              ({ id }) => id === participant.id
            );

          if (removeParticipants && removeParticipants.length > 0) {
            removeParticipants.forEach((removeParticipant) =>
              existingParticipantsPbo.value.data.results.remove(
                removeParticipant
              )
            );
          }
        });
      });
    } else {
      if (!NODE_ENV_PRODUCTION) {
        console.debug(
          `removeLocalParticipant did not find existing list of Participants for Conversation ${conversationId}, loading them now...`
        );
      }
      void this.loadConversationParticipants(conversationId);
    }
  };
}

export default ParticipantStore;
