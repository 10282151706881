import styled from 'styled-components';

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  text-align: center;
`;

const ContactAttribute = styled.div<{
  $secundary?: boolean;
}>`
  display: inline-flex;
  ${({ $secundary, theme }) =>
    $secundary
      ? `
    color: ${theme.colors.secondary};
  `
      : `
    color: black;
  `}
`;

const Separator = styled.div`
  width: 100%;
  height: 5px;
`;

const EditContactLink = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  margin-top: 16px;
  cursor: pointer;
`;

const EmailAttribute = styled.div`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

export const Styled = {
  ContactAttribute,
  ContactInfo,
  Separator,
  EditContactLink,
  EmailAttribute,
};
