import type { InfoWrapperProps } from './interfaces';
import { TextButton } from 'Components/shared/TextButton';
import styled from 'styled-components';
import { Icon } from '../shared/Icon';

const Container = styled.div`
  margin-top: 30px;
  margin-bottom: -7px;
`;

const Label = styled.h3`
  font: normal normal bold 16px/18px Open Sans;
  margin-bottom: 10px;
`;

const Logo = styled.img`
  width: 18px;
  height: 18px;
`;

const Email = styled.p`
  font: normal normal normal 13px/18px Open Sans;
`;

const InfoWrapper = styled.div<InfoWrapperProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-bottom: ${({ isSourceStale }) => (isSourceStale ? '20px' : '-7px')};
`;

const Warning = styled.div`
  font-size: 12px;
  line-height: 18px;
  margin-right: 5px;
`;

const WarningTitle = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.warning};
`;

const WarningText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

const WarningContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const WarningIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.warning} !important;
`;

const ManageSourcesButton = styled(TextButton)`
  font-weight: 600;
`;

export const Styled = {
  Container,
  Label,
  Logo,
  Email,
  InfoWrapper,
  Warning,
  WarningTitle,
  WarningText,
  WarningContainer,
  WarningIcon,
  ManageSourcesButton,
};
