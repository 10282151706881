import phoneIcon from 'Assets/images/menu-call.svg';
import chatIcon from 'Assets/images/sms-icon.svg';
import { CALL_STATE } from 'Constants/enums';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import {
  selectProperVideoIcon,
  selectProperVideoTooltipMessageForOneOnOnes,
} from 'Utils/videoConferenceUtils';

import { Styled } from './index.styles';
import { EntityActionButtonsProps } from './index.types';

export default function EntityActionButtons({
  isVideoCallDisabled,
  placeVideoCallConference,
  person,
  callDefaultUserLine,
  callState,
  onMessageUserClick,
}: EntityActionButtonsProps) {
  return (
      <Styled.ActionsGroup>
        {person && person.SupportsChat ? (
          <Popup
            inverted
            position="bottom left"
            content="Start Message"
            trigger={
              <Styled.LabeledButton>
                <Styled.ImageButton
                  onClick={onMessageUserClick}
                  id="chat-info-person"
                  data-testid="chat-info-person"
                >
                  <img src={chatIcon} />
                </Styled.ImageButton>
                <span>Chat</span>
              </Styled.LabeledButton>
            }
          />
        ) : (
          /*----- SMS BUTTON -----*/

          <Popup
            inverted
            position="bottom right"
            content="SMS User"
            trigger={
              <Styled.LabeledButton>
                <Styled.ImageButton
                  onClick={onMessageUserClick}
                  id="sms-info-person"
                  data-testid="sms-info-person"
                >
                  <div>SMS</div>
                </Styled.ImageButton>
                <span>SMS</span>
              </Styled.LabeledButton>
            }
          />
        )}
        {!isVideoCallDisabled && (
          <Popup
            inverted
            position="bottom left"
            trigger={
              <Styled.LabeledButton>
                <Styled.ImageButton
                  onClick={() => placeVideoCallConference(person.id)}
                  id="video-call-info-person"
                  data-testid="video-call-info-person"
                >
                  <img
                    src={selectProperVideoIcon(
                      null,
                      null,
                      null,
                      null,
                      'group-info'
                    )}
                  />
                </Styled.ImageButton>
                <span>Video</span>
              </Styled.LabeledButton>
            }
          >
            <div>
              <div>
                {selectProperVideoTooltipMessageForOneOnOnes(null, null)}
              </div>
            </div>
          </Popup>
        )}
        {/*----- CALL BUTTON -----*/}
        <Popup
          inverted
          position="bottom left"
          content={
            callState !== CALL_STATE.Available
              ? 'You are currently on a call with this person'
              : 'Start Call'
          }
          trigger={
            <Styled.LabeledButton>
              <Styled.ImageButton
                onClick={(e) => {
                  if (callState === CALL_STATE.Available) {
                    callDefaultUserLine(e);
                  }
                }}
                id="phone-call-info-person"
                data-testid="phone-call-info-person"
              >
                <img id="phone-call" src={phoneIcon} />
              </Styled.ImageButton>
              <span>Call</span>
            </Styled.LabeledButton>
          }
        />
      </Styled.ActionsGroup>
  );
}
