import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Styled } from './index.styles';

export default function EntityAttribute({
  name,
  userStatus,
  children,
}: {
  name: string;
  userStatus: { title: string; message: string };
  children?: React.ReactNode;
}) {
  return (
    <Styled.DisplayName data-private>
      {name}
      <Popup
        inverted
        trigger={
          <Styled.UserStatus>{userStatus?.title || ''}</Styled.UserStatus>
        }
        content={userStatus?.message || ''}
        position={'top right'}
      />
      {children && <Styled.ChildrenSlot>{children}</Styled.ChildrenSlot>}
    </Styled.DisplayName>
  );
}
