import styled from 'styled-components';
import { UserAvatar } from '../shared/UserAvatar';

const EntityProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledUserAvatar = styled(UserAvatar)`
  width: 80px;
  height: 80px;
  font-size: 18px;
`;

const AddContactLink = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  cursor: pointer;
`;

export const Styled = {
  EntityProfileHeader,
  UserAvatar: StyledUserAvatar,
  AddContactLink,
};
