import styled from 'styled-components';

const LoginButton = styled.a<{ disabled?: boolean }>`
  cursor: pointer;
  text-decoration: none;
  font: normal normal 600 13px/18px Open Sans;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.primary};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};

  &:hover {
    color: ${({ theme }) => theme.colors.linkHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.linkClick};
  }
`;

export const Styled = {
  LoginButton,
};
