import styled from 'styled-components';

const PinnedMessages = styled.div<{ $zIndex: number }>`
  position: absolute !important;
  right: 0px;

  ${({ $zIndex }) =>
    $zIndex &&
    `
    z-index: ${$zIndex} !important;
  `}
`;

export const Styled = {
  PinnedMessages,
};
