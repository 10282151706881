import { ChatContentItem } from 'Components/ChatContentItem';
import {
  BV_ENV_LOCAL_OR_DEVELOPMENT,
  TIMEZONE_IDENTIFIER,
} from 'Constants/env';
import _, { isEmpty } from 'lodash';
import { now } from 'mobx-utils';
import { MessageModel } from 'Models/MessageModel';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Feed, Icon, Modal } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { isElementInViewport } from 'Utils/domPositioning';
import { ChatContentItemProps } from '../ChatContentItem/types';
import { ChatDivider } from './ChatDivider';
import { IContextContentItemsGroupProps } from './interfaces';

export const ContextContentItemsGroup: React.FC<
  IContextContentItemsGroupProps
> = (props) => {
  const {
    conversationId,
    curConversation,
    deleteMessage,
    editMessage,
    editMessageDraftHtml,
    editMessageDraftRaw,
    getEmojiPickerState,
    getPerson,
    group,
    groupIndex,
    isEditingMessageId,
    isScrolled,
    loadLinkPreview,
    loggedInPersonId,
    loggedInUserActiveConferenceConversation,
    mentionListOpen,
    messageMentionFilter,
    messages,
    navigateToConferenceByConfId,
    newestMessageOwnedByUserId,
    resolveConversationLinkPath,
    scroll,
    scrollBottom,
    selectedMentionParticipantId,
    selectFilteredOtherParticipantPersonsInCurrentConversation,
    selectMarkedAsReadInfo,
    selectEpochMsSinceMarkedAsRead,
    selectParticipantPersons,
    selectPersonPresenceStatus,
    selectUnreadCounts,
    setConversationAndTotalUnreadCount,
    setEditMessageDraftHtml,
    setEditMessageDraftRaw,
    setEmojiPickerState,
    setIsEditingMessageId,
    setMentionListOpen,
    setMessageMentionFilter,
    setSelectedMentionParticipantId,
    stayScrolled,
    totalUnreadCount,
    updateMyLastReadMessage,
    selectPersonMessageStatus,
    listOfPinnedMessages,
    conversationStore,
    getFileDownloadLink,
    handleDownloadWithLink,
    setFileDeletePopup,
    setCopiedConferenceId,
    copiedConferenceId,
    copyToClipboard,
    getExtrContactByPhoneNumber,
    setMarkedAsReadInfo,
    messageIdToScroll,
    setMessageIdToScroll,
    isBackfillingToReadMessage,
    lastReadMessageId,
  } = props;

  const [showDebugMsg, setShowDebugMsg] = useState<boolean>(false);

  const unreadDivRef = useRef<HTMLDivElement>(null);

  const handleModalClose = () => {
    setShowDebugMsg(false);
  };

  const handleMarkAsReadClick =
    (from: 'componentDidMount' | 'render') => () => {
      const readMsg = messages.getReadMessage();
      const prevReadOrOldestMsgId =
        (readMsg !== null && readMsg.id) ||
        (messages.OldestMessage !== null && messages.OldestMessage.id) ||
        null;
      const newestMessageId = messages.NewestMessageId;
      const nextReadOrNewestMsgId = newestMessageId || group.NewestId;
      if (!isEmpty(nextReadOrNewestMsgId)) {
        //preventing to hide a NEW message divider from componentDidMount
        from === 'render' &&
          setMarkedAsReadInfo(conversationId, {
            markedEpochMs: now('frame'),
            messageId: prevReadOrOldestMsgId,
          });
        updateMyLastReadMessage(conversationId, nextReadOrNewestMsgId).then(
          () => {
            setConversationAndTotalUnreadCount(conversationId, 0, null, 0).then(
              () => {
                scrollBottom();
              }
            );
          }
        );
      }
    };

  /**
   * Scroll smoothly to the Unread Messages div, if it exists.
   *
   * Returns `true` if a scroll was performed, `false` otherwise.
   */
  const tryScrollUnreadDivRefIntoView = () => {
    const previousGroupHasUnreads =
      groupIndex > 0 && !messages.SortedGroups[groupIndex - 1].AllMessagesRead;
    if (
      !isEmpty(unreadDivRef.current) &&
      !previousGroupHasUnreads &&
      !group.AllMessagesRead
    ) {
      setMarkedAsReadInfo(conversationId, null); // clear out because divider bar will show
      setTimeout(() => {
        if (unreadDivRef.current !== null) {
          unreadDivRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      }, 50);
      return true;
    }
    return false;
  };

  const mountedRef = useRef(false);
  const prevProps = useRef<{
    messageIdToScroll?: string;
    conversationId?: string;
    isBackfillingToReadMessage?: boolean;
  }>({});

  // componentDidUpdate
  useEffect(() => {
    if (mountedRef.current) {
      // When switching to a new Conversation, scroll the unread messages divider into view if necessary
      if (messageIdToScroll !== '' && conversationId !== '') {
        if (
          (prevProps.current.messageIdToScroll !== messageIdToScroll &&
            conversationId !== prevProps.current.conversationId) ||
          conversationId === prevProps.current.conversationId
        ) {
          const messageHtml = document.getElementById(messageIdToScroll);

          // if messageHtml element is already visible on the screen, no need to scroll
          if (isElementInViewport(messageHtml)) {
            return;
          }

          // https://bitbucket.org/broadvoice/broadvoice.communicator/pull-requests/2364/diff#comment-thread-469730346
          // we have to wait for the message to be rendered before scrolling to it
          setTimeout(() => {
            messageHtml?.scrollIntoView({
              behavior: 'auto',
              block: 'center',
            });
          });

          setMessageIdToScroll('');

          return;
        }
      }

      if (
        prevProps.current.conversationId !== conversationId ||
        (!isBackfillingToReadMessage &&
          isBackfillingToReadMessage !==
            prevProps.current.isBackfillingToReadMessage)
      ) {
        tryScrollUnreadDivRefIntoView();
        return;
      }
      // If there's no messageId to scroll, nor unread message, jump to last read message, or last message of conversation
      if (lastReadMessageId !== '' && conversationId !== '') {
        let messageHtml = document.getElementById(lastReadMessageId);

        if (!messageHtml && curConversation?.lastMessageId) {
          messageHtml = document.getElementById(curConversation.lastMessageId);
        }

        // https://bitbucket.org/broadvoice/broadvoice.communicator/pull-requests/2364/diff#comment-thread-469730346
        // we have to wait for the message to be rendered before scrolling to it
        const timeout = setTimeout(() => {
          if (messageHtml) {
            messageHtml.scrollIntoView({
              behavior: 'auto',
              block: 'center',
            });
          } else {
            scroll(Infinity);
          }
        });

        return () => {
          clearTimeout(timeout);
        };
      }
    }

    prevProps.current = {
      messageIdToScroll,
      conversationId,
      isBackfillingToReadMessage,
    };
  }, [
    messageIdToScroll,
    conversationId,
    isBackfillingToReadMessage,
    lastReadMessageId,
    tryScrollUnreadDivRefIntoView,
    curConversation?.lastMessageId,
  ]);

  // componentDidMount
  useEffect(() => {
    const componentDidMount = async () => {
      if (!isEmpty(conversationId) && !messageIdToScroll) {
        tryScrollUnreadDivRefIntoView();
      }

      handleMarkAsReadClick('componentDidMount');
    };

    void componentDidMount();

    // Indicate that the component as already been mounted
    mountedRef.current = true;
  }, []);

  const previousGroupHasUnreads =
    groupIndex > 0 && messages.SortedGroups[groupIndex - 1].AnyMessagesUnread;
  const nextGroupHasOnlyUnreads =
    groupIndex < messages.SortedGroups.length - 1 &&
    messages.SortedGroups[groupIndex + 1].AllMessagesUnread;

  const showUnreadDivider =
    (group.AnyMessagesUnread || nextGroupHasOnlyUnreads) &&
    // TODO: Not sure if we need this? RP 2019-08-19: && group.ReadMessages !== null
    group.NewestMessage.personId !== loggedInPersonId && // The latest Message isn't from the logged-in user
    !previousGroupHasUnreads &&
    messages.MsSinceNewestMessageCreatedOrAdded > 5000 &&
    (selectEpochMsSinceMarkedAsRead(conversationId) > 5000 ||
      selectEpochMsSinceMarkedAsRead(conversationId) === 0);

  const readMsg = messages.getReadMessage();

  let readOrNewestMsgCreated: moment.Moment;
  if (readMsg !== null) {
    readOrNewestMsgCreated = readMsg.CreatedMoment;
  } else if (messages.NewestMessage !== null) {
    readOrNewestMsgCreated = messages.NewestMessage.CreatedMoment;
  } else {
    readOrNewestMsgCreated = moment.tz(TIMEZONE_IDENTIFIER);
  }

  // if(BV_ENV_LOCAL_OR_DEVELOPMENT){
  //     console.debug(`CCIL readOrOldestMsgCreated for ${conversationId}: ${readOrNewestMsgCreated.toISOString()} (readMessageId ${get(readMsg, 'id', '<empty>')})`)
  // }
  const dividerKey = `divider-${group.groupKey}`;
  const separator = !BV_ENV_LOCAL_OR_DEVELOPMENT ? (
    <ChatDivider groupDate={group.groupKey} />
  ) : (
    <Modal
      key={dividerKey}
      id={dividerKey}
      trigger={<ChatDivider groupDate={group.groupKey} />}
      open={showDebugMsg}
      basic
      size="small"
      onClose={handleModalClose}
      closeIcon={<Icon name="close" />}
    >
      <Modal.Header>{group.DisplayName}</Modal.Header>
      <Modal.Content className="msg-group-dump-content" scrolling>
        <pre>{group.SerializedJSON}</pre>
      </Modal.Content>
    </Modal>
  );

  const list: (MessageModel | boolean)[] =
    group.AllMessages &&
    _.uniqBy(
      group.AllMessages.filter((message) => !isNullOrUndefined(message.id)),
      'id'
    );

  if (list) {
    for (let index = list.length - 1; index >= 0; index--) {
      const message = list[index] as MessageModel;

      // Is the most recent read message
      if (message.isRead) {
        list.splice(index + 1, 0, showUnreadDivider);
        break;
      }
    }
  }

  const readMessageProps = {
    setFileDeletePopup,
    handleDownloadWithLink,
    getFileDownloadLink,
    listOfPinnedMessages,
    conversationId,
    curConversation,
    deleteMessage,
    editMessage,
    editMessageDraftHtml,
    editMessageDraftRaw,
    getEmojiPickerState,
    getPerson,
    isEditingMessageId,
    isScrolled,
    loadLinkPreview,
    loggedInPersonId,
    loggedInUserActiveConferenceConversation,
    mentionListOpen,
    messageMentionFilter,
    navigateToConferenceByConfId,
    newestMessageOwnedByUserId,
    resolveConversationLinkPath,
    scroll,
    scrollBottom,
    selectedMentionParticipantId,
    selectFilteredOtherParticipantPersonsInCurrentConversation,
    selectParticipantPersons,
    selectPersonPresenceStatus,
    selectUnreadCounts,
    setConversationAndTotalUnreadCount,
    setEditMessageDraftHtml,
    setEditMessageDraftRaw,
    setEmojiPickerState,
    setIsEditingMessageId,
    setMentionListOpen,
    setMessageMentionFilter,
    setSelectedMentionParticipantId,
    stayScrolled,
    updateMyLastReadMessage,
    selectPersonMessageStatus,
    setCopiedConferenceId,
    copiedConferenceId,
    copyToClipboard,
    getExtrContactByPhoneNumber,
  } as ChatContentItemProps;

  const unreadMessageProps = props;

  return (
    <div
      id={`ccig_${group.groupKey}`}
      key={`ccig-${group.groupKey}`}
      className="context-content-items-group flex-grow-shrink flex-basis-100pct"
    >
      {separator}
      <Feed size="large" key={`feed-${group.groupKey}`}>
        {list &&
          list.map((messageOrSeparator) => {
            if (typeof messageOrSeparator === 'boolean') {
              return messageOrSeparator ? (
                <div
                  key={`unreadnmdiv-${group.groupKey}`}
                  id={`unreadnmdiv-${group.groupKey}`}
                  className="divider-unread flex-row"
                  title={`${totalUnreadCount} Unread Messages`}
                  ref={unreadDivRef}
                >
                  <div
                    className="divider-unread-left flex-shrink"
                    onClick={scrollBottom}
                  >
                    JUMP
                  </div>
                  <div className="divider-unread-mid flex-grow-shrink">
                    {totalUnreadCount} New Message
                    {totalUnreadCount > 1 ? 's' : ''}
                    {` Since ${readOrNewestMsgCreated.format('LT [on] LL')}`}
                  </div>
                  <div
                    className="divider-unread-right flex-shrink"
                    onClick={handleMarkAsReadClick('render')}
                  >
                    MARK AS READ
                  </div>
                </div>
              ) : (
                <div
                  key={`unreadnmdiv-${group.groupKey}`}
                  id={`unreadnmdiv-${group.groupKey}`}
                  className="flex-row"
                  style={{ display: 'none!important', visibility: 'hidden' }}
                  ref={unreadDivRef}
                />
              );
            }

            return (
              <React.Fragment key={messageOrSeparator.id}>
                {
                  // Special case: show here if it is the oldest Message, ex. the first Message in a new Conversation
                  messageOrSeparator.isRead &&
                    messages.MsSinceNewestMessageCreatedOrAdded > 5000 &&
                    !isNullOrUndefined(
                      selectMarkedAsReadInfo(conversationId)
                    ) &&
                    selectMarkedAsReadInfo(conversationId).messageId ===
                      messageOrSeparator.id &&
                    messages.OldestMessageId === messageOrSeparator.id && (
                      <div
                        className="marked-messages-divider flex-row"
                        id={`mmd-${group.groupKey}`}
                        key={`readnmdiv-${group.groupKey}`}
                      >
                        <div className="marked-messages-text flex-shrink">
                          NEW MESSAGES
                        </div>
                        <div className="marked-messages-line flex-grow-shrink" />
                      </div>
                    )
                }

                <ChatContentItem
                  message={messageOrSeparator}
                  messageGroup={group}
                  conversationStore={conversationStore}
                  {...(messageOrSeparator.isRead
                    ? (readMessageProps as ChatContentItemProps)
                    : unreadMessageProps)}
                />

                {
                  // Special case: hide here if it is the oldest Message, ex. the first Message in a new Conversation
                  messageOrSeparator.isRead &&
                    messages.MsSinceNewestMessageCreatedOrAdded > 5000 &&
                    !isNullOrUndefined(
                      selectMarkedAsReadInfo(conversationId)
                    ) &&
                    selectMarkedAsReadInfo(conversationId).messageId ===
                      messageOrSeparator.id &&
                    messages.OldestMessageId !== messageOrSeparator.id && (
                      <div
                        className="marked-messages-divider flex-row"
                        id={`mmd-${group.groupKey}`}
                        key={`readnmdiv-${group.groupKey}`}
                      >
                        <div className="marked-messages-text flex-shrink">
                          NEW MESSAGES
                        </div>
                        <div className="marked-messages-line flex-grow-shrink" />
                      </div>
                    )
                }
              </React.Fragment>
            );
          })}
      </Feed>
    </div>
  );
};
