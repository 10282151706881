import styled from 'styled-components';

const Container = styled.div`
  max-height: 120px;
  width: 100%;
  padding-bottom: 12px;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  scrollbar-color: #ccc transparent;
  scroll-behavior: auto;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
`;

const ContactItem = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 28px;
`;

const ContactName = styled.b`
  font-weight: 700;
`;

const EditContactLink = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  margin-top: 16px;
  cursor: pointer;
`;

export const Styled = {
  Container,
  ContactItem,
  ContactName,
  EditContactLink,
};
