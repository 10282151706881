import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

const SuccessScreen = styled.div`
  color: ${({ theme }) => theme.colors.sidebar};
`;

const SuccessMessage = styled.div`
  font-size: 26px;
  margin-top: 60px;
`;

const WaitMessage = styled.div`
  font-size: 16px;
  margin-top: 30px;
`;

const CloseButton = styled.img`
  position: absolute;
  max-width: 15px;
  top: 40px;
  right: 60px;

  &:hover {
    cursor: pointer;
  }
`;

// Keep "!important" to override semanti ui styles
const ViewContactsButton = styled(Button)`
  color: ${({ theme }) => theme.colors.base} !important;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  height: 40px !important;
  width: 360px !important;
  padding-top: 13px !important;
  margin-top: 60px !important;
  font-weight: 700 !important;
`;

export const Styled = {
  SuccessScreen,
  SuccessMessage,
  WaitMessage,
  CloseButton,
  ViewContactsButton,
};
