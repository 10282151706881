import type { GoogleAuthProps } from './types';
import googleIcon from 'Assets/images/google-icon.svg';
import { NYLAS_V3 } from 'Constants/featureFlags';
import useFeatureFlags from 'Hooks/featureFlags/useFeatureFlags';
import React from 'react';
import ProviderButton from '../ProviderButton';
import { useGoogleAuthLogin } from './useGoogleAuthLoginHook';

const GoogleAuthLogin = ({ person, testId }: GoogleAuthProps) => {
  const { [NYLAS_V3]: isUsingNylasV3 } = useFeatureFlags([NYLAS_V3]);
  const { handleLoginClick, SCOPES } = useGoogleAuthLogin({
    person,
    isUsingNylasV3: isUsingNylasV3,
  });

  return (
    <>
      {SCOPES && (
        <ProviderButton
          text="Sign in with Google"
          logo={googleIcon}
          onClick={handleLoginClick}
          testId={`${testId}-googleAuthLogin-buttonSignIn`}
        />
      )}
    </>
  );
};

export default GoogleAuthLogin;
