import styled, { CSSProperties } from 'styled-components';

const Panel = styled.div<{
  $zIndex: number;
  $visibility: CSSProperties['visibility'];
}>`
  position: absolute;
  right: 0;
  top: 0;
  width: 260px;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 14px;
  max-height: calc(100% - 70px); // 70px is the height of the header
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  color: black;
  box-shadow: -4px 0px 30px rgba(0, 0, 0, 0.1);
  visibility: ${({ $visibility }) => $visibility || 'hidden'};
  ${({ $zIndex }) => $zIndex && `z-index: ${$zIndex} !important;`};
`;

export const Styled = {
  Panel,
};
