import { DropdownItemProps, Loader } from 'semantic-ui-react';
import React, { useCallback, useState } from 'react';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from 'Constants/stores';
import { inject, observer } from 'mobx-react';

import { ConfirmationModal } from 'Components/shared/ConfirmationModal';
import { FeatureFlag } from '../shared/FeatureFlag';
import GoogleAuthLogin from '../GoogleAuthLogin';
import ICloudAddressBook from '../ICloudAddressBook';
import { ISourceAccount } from 'Interfaces/SourceAccount';
import Microsoft from '../Microsoft';
import MicrosoftBusiness from '../MicrosoftBusiness';
import MicrosoftPersonal from '../MicrosoftPersonal';
import { NYLAS_V3 } from 'Constants/featureFlags';
import type { SourceBookProps } from './types';
import { SourcesAddressBookItem } from './SourcesAddressBookItem';
import { Styled } from './index.styles';
import SuccessScreenSource from '../SuccessScreenSource';
import bhiveIcon from 'Assets/images/bhive-logo-short.svg';
import withRouter from 'Hocs/WithRouter';

export const permanentProviders: ISourceAccount[] = [
  {
    id: 'bHivePersonal',
    name: 'Personal contacts',
    provider: 'Bhive',
    providerImageSmall: bhiveIcon,
    text: 'Your personal contacts stored on b-hive and visible only to you. Source can not be removed.',
    email: '',
    providerAccountId: '',
    platformUserId: null,
    platformAccountId: null,
    createdAt: new Date().toString(),
    scopes: ['contacts'],
  },
  {
    id: 'bHiveOrganization',
    name: 'Organization contacts',
    provider: 'Bhive',
    providerImageSmall: bhiveIcon,
    text: 'Your account contacts stored on b-hive and visible to everyone on your account. Source can not be removed.',
    email: '',
    providerAccountId: '',
    platformUserId: null,
    platformAccountId: null,
    createdAt: new Date().toString(),
    scopes: ['contacts'],
  },
];

export const SourcesAddressBookBase = ({
  person,
  navigate,
  notification,
  titleFor,
  testId: parentTestId,
}: SourceBookProps) => {
  const testId = `${parentTestId}-sourcesAddressBook`;
  const [showRemoveNotification, setShowRemoveNotification] =
    useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [sourceForRemoval, setSourceForRemoval] = useState(null);

  const removeSource = async () => {
    const resp = await person.removeSource(sourceForRemoval);

    if (resp) {
      setShowRemoveNotification(true);
      setShowRemoveModal(false);

      const timeout = setTimeout(() => {
        setShowRemoveNotification(false);
        clearTimeout(timeout);
      }, 1300);
    }
  };

  const makeProperNamingRemoval = (): [string, string] => {
    return titleFor === 'contacts'
      ? ['sources', 'contacts']
      : ['calendars', 'calendar events'];
  };

  const toggleRemoveModal = (source: ISourceAccount) => () => {
    setShowRemoveModal(!showRemoveModal);
    setSourceForRemoval(source);
  };

  const resyncSource = (source: ISourceAccount) => () =>
    void person.resyncSource(source);

  const showContactsBasedOnSource = useCallback(
    (source: ISourceAccount) => () => {
      const text =
        source.email.charAt(0).toUpperCase() + source.email.slice(1);
      const providerValue =
        source.name === 'Personal contacts'
          ? 'B_HIVE_PERSONAL'
          : source.name === 'Organization contacts'
          ? 'B_HIVE_SHARED'
          : source.id;
      const pickedSource: DropdownItemProps = {
        key: source.id,
        image: { avatar: true, src: source.providerImage },
        text: text,
        value: providerValue,
      };
      person.setContactFilterValue(pickedSource);
      person.getSearchListContacts(
        20,
        1,
        pickedSource.value.toString() || '',
        person.contactSearchTerm
      );
      navigate('/addressBook/contacts');
    },
    [navigate]
  );

  const handleProperName = () => {
    return person.changeProviderName(
      person.addedNewSource.sourceProvider || ''
    );
  };

  const filterByScopes = (data: ISourceAccount[]) => {
    const filterScope = titleFor === 'contacts' ? 'contacts' : 'calendar';
    return data?.filter((item) =>
      item.scopes.some((scope) => scope.includes(filterScope))
    );
  };

  const data = filterByScopes([
    ...permanentProviders,
    ...person.allSources,
  ]);

  const providerName = handleProperName() || '';
  const [removeFromWhere, removeWhat] = makeProperNamingRemoval();

  return (
    <Styled.SourceList>
      {person.addedNewSource.show ? (
        <SuccessScreenSource
          typeOfAddedAction={titleFor}
          alreadyAddedMessage={person.addedNewSource.alreadyAddedMessage}
          provider={providerName}
          person={person}
          showContactsBasedOnSource={showContactsBasedOnSource}
        />
      ) : (
        <>
          <Styled.Title>Add new source</Styled.Title>
          <Styled.SourceBoxes>
            <GoogleAuthLogin
              {...{ person }}
              for={titleFor}
              testId={testId}
            />
            <FeatureFlag
              flag={NYLAS_V3}
              fallback={
                <>
                  <MicrosoftBusiness
                    {...{ person }}
                    for={titleFor}
                    testId={testId}
                  />

                  <MicrosoftPersonal
                    {...{ person }}
                    notification={notification}
                    for={titleFor}
                    testId={testId}
                  />
                </>
              }
            >
              <Microsoft {...{ person }} for={titleFor} testId={testId} />
            </FeatureFlag>
            <FeatureFlag
              flag={NYLAS_V3}
              fallback={
                <ICloudAddressBook
                  {...{ person }}
                  notification={notification}
                  for={titleFor}
                  testId={testId}
                />
              }
            ></FeatureFlag>
          </Styled.SourceBoxes>

          <Styled.Title>Existing {titleFor}</Styled.Title>

          <Styled.SourceWrapper>
            {data?.length > 0 ? (
              data.map((source) => (
                <SourcesAddressBookItem
                  source={source}
                  titleFor={titleFor}
                  onShowClick={showContactsBasedOnSource(source)}
                  onResyncClick={resyncSource(source)}
                  onRemoveClick={toggleRemoveModal(source)}
                  testId={testId}
                  key={source.id}
                />
              ))
            ) : data.length === 0 ? (
              <div>
                Your list is empty. Click on the buttons below to add new
                Source to the list.
              </div>
            ) : (
              <Loader />
            )}
          </Styled.SourceWrapper>

          <Styled.Disclaimer variant="paragraph">
            Your contacts will be synchronized between b-hive and your
            external account. Contacts will be visible only to you. Any
            changes in the b-hive will be propagated to your external
            source and vice versa.
          </Styled.Disclaimer>

          {showRemoveNotification && (
            <Styled.RemoveNotification>
              Source {sourceForRemoval?.name} succesfully removed.
            </Styled.RemoveNotification>
          )}

          <ConfirmationModal
            show={showRemoveModal}
            onClose={toggleRemoveModal(null)}
            onConfirm={removeSource}
            textConfirm="Yes"
            textCancel="No"
            testid={`${testId}-remove`}
          >
            <div>
              You are about to remove <b>{sourceForRemoval?.name}</b> from
              your {removeFromWhere}. All related {removeWhat} will be
              removed. Are you sure?
            </div>
          </ConfirmationModal>
        </>
      )}
    </Styled.SourceList>
  );
};

export const SourcesAddressBook = inject(
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(withRouter(observer(SourcesAddressBookBase)));
