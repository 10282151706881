import type { SecondaryMenuOptionButtonProps } from './interfaces';
import * as React from 'react';
import { Styled } from './index.styles';

export const SecondaryMenuOptionButton: React.FC<SecondaryMenuOptionButtonProps> = ({
  text,
  to,
  show = true,
  disabled = false,
  testid,
  badge,
}) =>
  show ? (
    <Styled.NavLink
      {...{ to }}
      $disabled={disabled}
      data-testid={`${testid}-button${text}`}
    >
      <Styled.Heading variant="h6" noMargin>
        {text}
        {badge && <Styled.BadgeContainer>{badge}</Styled.BadgeContainer>}
      </Styled.Heading>
    </Styled.NavLink>
  ) : null;
