import type { MicrosoftProps } from './types';
import logo from 'Assets/images/microsoft-icon.svg';
import {
  AZURE_GRAPH_APP_CLIENT_ID,
  CALENDAR_IDS,
  CALENDAR_SCOPE,
  IS_ELECTRON,
  REDIRECT_URL,
  SHOW_CALENDAR,
  MICROSOFT_SOURCE_SCOPES,
  AZURE_GRAPH_OAUTH_URL,
} from 'Constants/env';
import { has } from 'lodash';
import { Scope } from 'Models/Calendar';
import React, { useEffect, useState } from 'react';
import { handleCodeReceived, sendIpcLoginUrl } from 'Utils/ipcRendererEvents';
import { randomString } from 'Utils/random';
import ProviderButton from '../ProviderButton';

export const handleMicrosoftLogIn = ({ propsFor, onLogin }) => {
  const scope = encodeURIComponent(
    MICROSOFT_SOURCE_SCOPES.filter((a) => a).join(' ')
  );

  const handleWebRedirection = () =>
    (window.location.href = microsoftRedirectionLink);

  const microsoftRedirectionLink = `${AZURE_GRAPH_OAUTH_URL}?
    client_id=${AZURE_GRAPH_APP_CLIENT_ID}&
    response_type=code&
    redirect_uri=${encodeURIComponent(`${REDIRECT_URL}/addressBook/sources`)}&
    response_mode=query&
    prompt=select_account&
    scope=${scope}&
    state=${propsFor}&
    nonce=${randomString(6)}`;

  if (IS_ELECTRON && has(window, 'ipcRenderer')) {
    handleCodeReceived((code) => {
      onLogin(code);
    });
    sendIpcLoginUrl(microsoftRedirectionLink);
  } else {
    handleWebRedirection();
  }
};

const Microsoft = ({
  person,
  for: propsFor,
  testId,
}: MicrosoftProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const showCalendar =
    SHOW_CALENDAR || CALENDAR_IDS?.includes(person.loggedInPersonId);

  const onLogin = (code) => {
    if (loading) {
      return;
    }

    const scopesCode = ['contacts'] as Scope[];

    showCalendar && scopesCode.push(CALENDAR_SCOPE);
    setLoading(true);

    person
      .sendCodeOfProvider(code, 'microsoft', scopesCode)
      .then((res) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { search } = window?.location;

    if (!IS_ELECTRON) {
      if (search.includes('code=')) {
        setLoading(true);
        const code = search.split('&').find((item) => !!item.includes('code'));
        const scopesCode = ['contacts'] as Scope[];
        showCalendar && scopesCode.push(CALENDAR_SCOPE);
        person
          .sendCodeOfProvider(code.split('code=')[1], 'microsoft', scopesCode)
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }, []);

  return (
    <ProviderButton
      text="Sign in with Microsoft"
      logo={logo}
      isLoading={loading}
      onClick={() => {
        handleMicrosoftLogIn({ propsFor, onLogin });
      }}
      testId={`${testId}-microsoft-buttonSignIn`}
    />
  );
};

export default Microsoft;
