import EntityProfileHeaderObserver from 'Components/EntityProfileHeader';
import EntityProfileInfoObserver from 'Components/EntityProfileInfo';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect } from 'react';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { bugsnagClient } from 'Utils/logUtils';
import { Styled } from './index.styles';
import { EntityProfileComponentProps } from './interfaces';
/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class EntityProfile
 */
export const EntityProfile: React.FC<EntityProfileComponentProps> = ({
  contact,
  person,
  extrContacts,
  ...props
}) => {
  useEffect(() => {
    if (isEmpty(contact) && isEmpty(person) && isEmpty(extrContacts)) {
      bugsnagClient.notify(
        'Either the contact or person prop is required',
        (event) => {
          event.context = 'EntityProfile';
          event.addMetadata('custom', {
            function:
              'componentDidMount | Either the contact or person prop is required',
            props: props,
          });
        }
      );
      props.navigate('/addressBook/contacts');
    }
    pushToGTMDataLayer('profileView', {
      profileType: isEmpty(contact) ? 'Person' : 'Contact',
    });
  }, [contact, extrContacts, person, props]);

  return (
    <Styled.Container>
      <EntityProfileHeaderObserver
        contact={contact}
        extrContacts={extrContacts}
        person={person}
      />
      <EntityProfileInfoObserver
        contact={contact}
        extrContacts={extrContacts}
        person={person}
      />
    </Styled.Container>
  );
};

export default observer(EntityProfile);
